import React from "react";
import i18n from "i18next";
import { Link } from "react-router-dom";
import { ConfigurableLabel } from "../components";
import { Helmet } from "react-helmet";
import { config } from "../Config";
import { PanelHeader } from "./PanelHeader";
import { SubscriptionFeature } from "../report/util";
import { ConfigurableFeature } from "./configurable/ConfigurableFeature";
import { accountFeatures, TS, useSSelector } from "../util";
import "./Labs.scss";
import DrainageMapFirstExampleImage from "../../img/settings/drainage_map_1.png";
import DrainageMapSecondExampleImage from "../../img/settings/drainage_map_2.png";
import SoilPotencyMapFirstExampleImage from "../../img/settings/soil_potency_map_1.png";
import SoilPotencyMapSecondExampleImage from "../../img/settings/soil_potency_map_2.png";
import ShadowMapFirstExampleImage from "../../img/settings/shadow_map_1.png";
import ShadowMapSecondExampleImage from "../../img/settings/shadow_map_2.png";
import WDVIImagesFirstExampleImage from "../../img/settings/WDVI_images_1.png";
import WDVIImagesSecondExampleImage from "../../img/settings/WDVI_images_2.png";
import WDVIImagesThirdExampleImage from "../../img/settings/WDVI_images_3.png";
import FieldSelectionToolExampleImage from "../../img/settings/field_selection_tool.png";
import EWSExampleImage from "../../img/settings/ews.png";
import GrowthCourseExampleImage from "../../img/settings/growth_course.png";

const Features = () => {
  const features = useSSelector((s) => accountFeatures(s.activeUser.account));
  return (
    <>
      <ConfigurableFeature
        setting="showDrainageMap"
        name={i18n.t("Drainage map")}
        show={config.shadow_map}
      >
        <p>
          <TS t="settings_drainage_map" />
        </p>
        <img alt={i18n.t("example")} src={DrainageMapFirstExampleImage} height="180" />
        <img alt={i18n.t("example")} src={DrainageMapSecondExampleImage} height="180" />
      </ConfigurableFeature>
      <ConfigurableFeature setting="showSoilPotencyMap" name={i18n.t("Soil Potential Map")}>
        <p>
          <TS t="settings_soil_potency_map" />
        </p>
        <img alt={i18n.t("example")} src={SoilPotencyMapFirstExampleImage} height="180" />
        <img alt={i18n.t("example")} src={SoilPotencyMapSecondExampleImage} height="180" />
      </ConfigurableFeature>
      <ConfigurableFeature setting="showShadowMap" name={i18n.t("Shadow map")}>
        <p>
          <TS t="settings_shadow_map" />
        </p>
        <img alt={i18n.t("example")} src={ShadowMapFirstExampleImage} height="155" />
        <img alt={i18n.t("example")} src={ShadowMapSecondExampleImage} height="155" />
      </ConfigurableFeature>
      <ConfigurableFeature setting="showWDVIImages" name={i18n.t("WDVI images")}>
        <p>
          <TS t="settings_WDVI_images" />
        </p>
        <img alt={i18n.t("example")} src={WDVIImagesFirstExampleImage} height="110" />
        <img alt={i18n.t("example")} src={WDVIImagesSecondExampleImage} height="110" />
        <img alt={i18n.t("example")} src={WDVIImagesThirdExampleImage} height="110" />
      </ConfigurableFeature>
      <ConfigurableFeature
        setting="showLease"
        name={i18n.t("Lease")}
        show={config.show.includes("lease")}
      >
        <p>
          <TS t="settings_lease" />
        </p>
      </ConfigurableFeature>
      <ConfigurableFeature setting="useFieldSelectionTool" name={i18n.t("Field selection tool")}>
        <p>
          <TS t="settings_field_selection_tool" />
        </p>
        <img
          alt={i18n.t("example")}
          className="center-block"
          src={FieldSelectionToolExampleImage}
          height="180"
        />
      </ConfigurableFeature>
      <ConfigurableFeature setting="showGrowthCourse" name={i18n.t("Growth course")}>
        <p>
          <TS t="settings_growth_course" />
        </p>
        <img
          alt={i18n.t("example")}
          className="center-block"
          src={GrowthCourseExampleImage}
          height="180"
        />
      </ConfigurableFeature>
      <ConfigurableFeature setting="showEWS" name={i18n.t("Infection radar")} show={features.ews}>
        <p>
          <TS t="settings_ews_feature" />
        </p>
        <img alt={i18n.t("example")} className="center-block" src={EWSExampleImage} height="180" />
      </ConfigurableFeature>
      <ConfigurableFeature
        setting="showPrecipDeficit"
        name={i18n.t("Precipitation deficit")}
        show={features.precipDeficit}
      >
        <p>
          <TS t="settings_precip_deficit" />
        </p>
      </ConfigurableFeature>
    </>
  );
};

export const Settings = ({ select }) => (
  <article className="mod_bb mod_bb_settings active">
    <hr className="mobile-hr" />
    <Helmet>
      <title>{i18n.t("Settings")}</title>
    </Helmet>
    <main className="bb_settings">
      <PanelHeader onHide={() => select()}>
        <span>{i18n.t("Settings")}</span>
        <ConfigurableLabel inHeader={true} includesLink={false} showDescription={false} />
        <SubscriptionFeature show={true} title="" description="" />
      </PanelHeader>

      <div className="row p-3">
        <div className="col-12">
          {i18n.t("TextSettingsInfo")} <a href="mailto:service@dacom.nl">service@dacom.nl</a>.
        </div>
      </div>
      <Features />
      <div className="row feedback-placeholder p-3">
        <div className="col-12">
          <h4>{i18n.t("Feedback")}</h4>
          <p>
            {i18n.t("If you have any questions or comments, email us at")}{" "}
            <a href="mailto:service@dacom.nl">service@dacom.nl</a>
            {". "}
            {i18n.t("Or use our contact form under")}{" "}
            <Link to="/page/contact">{i18n.t("Contact")}</Link>.
          </p>
        </div>
      </div>
    </main>
  </article>
);
