/* eslint-disable camelcase */
import L, { LatLng, LatLngTuple } from "leaflet";
import axios from "axios";

export type Country = "NL" | "BE" | "WA" | "DK" | "FR" | "DE_NW" | "DE_NI" | "DE_SH" | "AT" | "__";
type CountryCodes = "nl" | "be" | "dk" | "fr" | "de" | "at";
type Site = {
  code: Country;
  name: string;
  url: string;
  feature_prefix: string;
  beta?: boolean;
  free_trial?: boolean;
  country_code: CountryCodes;
};

declare global {
  interface Window {
    bb_country: Country;
    bb_sites: Site[];
    bb_language: string;
    bb_version: string;
    RAVEN_OPTIONS: object;
    AdButler: object;
    ads: object[];
    abkw: string;
    loadedAdbutlerId: number;
  }
}

interface StoreResponse {
  codes: Record<string, string>;
  groups: Record<string, GroupType>;
  center: LatLngTuple;
  bounds: [number, number, number, number];
  GEO_REFERENCE_URL: string;
  LAYER_URL: string;
}

type IdName = {
  id: string;
  name: string;
};

export type GroupType = {
  color: string;
  name: string;
  id?: number;
  icon?: string;
};
export type CodeType = IdName & { group?: GroupType };

class Store {
  readonly bounds: [number, number, number, number];
  center: LatLng;
  readonly bb_country: string;
  readonly __: boolean;
  countryFilter: string;
  readonly bb_sites: Site[];
  readonly bb_site: Site;
  bb_productPage: string;
  bb_dataPage: string;
  bb_apiPage: string;
  countries: { DK: { token: string } };
  language: string;
  native_language: string;
  isNative: boolean;
  debug: boolean;
  development: boolean;
  loaded: boolean;
  codes: Record<string, CodeType>;
  groups: Record<string, GroupType>;
  names: Record<string, IdName>;
  bb_orderPage: string;
  LAYER_URL: string;
  SATELLITE_URL: string;
  SFAM_URL: string;
  DACOM_URL: string;
  FRONT_URL: string;
  crop_year: number;
  projection: string;
  crop_year_start: number;
  history_free: 1;
  satellite_start: string;
  GEO_REFERENCE_URL: string;
  GEOSERVER_URL: string;
  show: string[];
  cadastre: boolean;
  shadow_map: boolean;
  SEARCH_URL: string;
  labs: boolean;
  filters: string[]; // ownership, zonea
  MAX_FREE_CLICKS?: number;
  cookieDays: number;
  tokenKeyPerHost: Record<string, string>;
  youtubeId?: string;

  constructor() {
    this.bb_country = window.bb_country || "NL";
    this.__ = this.bb_country === "__";
    this.countryFilter = this.__ ? "" : `&country=${this.bb_country}`;
    this.bb_sites = window.bb_sites;
    this.bb_site = this.bb_sites.find((bb_site) => bb_site.code === this.bb_country);
    this.language = window.bb_language || "nl";
    this.native_language = this.language;
    this.countries = { DK: { token: "ebd2ec7baaaa43e4d3f11ac8e8bd325c" } }; // https://services.kortforsyningen.dk/service?request=gettoken&login=dacom&password=Mark2018!
    this.SATELLITE_URL = "https://satellite.cropx.com";
    this.SEARCH_URL = "";
    this.debug = process.env.NODE_ENV !== "production";
    this.development = /^(localhost|development\.|127\.0\.0)/.test(window.location.host);
    this.show = [];
    this.cadastre = false;
    this.filters = ["soil"];
    this.MAX_FREE_CLICKS = ["NL", "BE", "WA", "LU"].includes(this.bb_country) ? 5 : 10;
    this.cookieDays = 60;
    this.isNative = true;
    this.youtubeId = { BE: "0CllSSxiFSc", NL: "eEWCO8O0eQg" }[this.bb_country];
  }

  fillData(json: StoreResponse) {
    const { groups, codes, center, ...rest } = json;
    this.codes = {};
    this.groups = groups;
    this.names = {};
    for (let [key, value] of Object.entries(codes)) {
      this.names[value] = this.codes[key] = { id: key, name: value };
    }
    for (let [group, value] of Object.entries(groups)) {
      for (const key of group.split(",")) {
        if (!this.codes[key]) this.codes[key] = { id: key, name: value.name };
        this.names[value.name] = this.codes[key];
        this.codes[key].group = value;
      }
    }
    this.center = L.latLng(center);
    Object.assign(this, rest, { loaded: true });
    this.tokenKeyPerHost = {
      [this.SFAM_URL]: "sfam",
      [this.LAYER_URL]: "reference",
      "http://reference.local:8001": "reference",
      "https://d2c.crop-r.com": "d2c",
      [this.SATELLITE_URL]: "sat",
      [this.SEARCH_URL.substring(0, this.SEARCH_URL.indexOf("/", 8))]: "reference",
    };
    this.isNative = this.language === this.native_language && !this.__;
    this.countryFilter = this.__ ? "" : `&country=${this.bb_country}`;
    return this;
  }

  loadData() {
    return new Promise((resolve, reject) => {
      if (this.loaded) return resolve();
      axios("/config.json?version=" + window.bb_version)
        .then((response) => resolve(this.fillData(response.data)))
        .catch(function (error) {
          console.error("Can not start application without configuration");
          const temp = document.getElementById("temporary-content");
          const loading = document.getElementById("loading");
          if (temp) temp.innerText = "Application failure, configuration not found";
          if (loading) loading.innerText = "Failed";
          reject("Failed");
        });
    });
  }
}
export const config = new Store();
