const translations = {
"da": {translation: {
    "<2ha, 2-4ha, 4-10ha and 10+ ha": "<2ha, mellem 2-4ha, 4 til 10ha og 10+ ha",
    "<a href=\"/authorize\">Login</a> to CropFields to view the cadastral field mapping": "<a href=\"/authorize\">Log ind</a> på Mark&Bonde for at se matrikelfeltkortlægningen",
    "<a href=\"{{href}}\">Subscribe</a> to CropFields to view the cadastral field mapping": "<a href=\"{{href}}\">Abonner</a> på Mark&Bonde for at se matrikelfeltkortlægningen",
    "<a href=\"{{href}}\">Subscribe</a> to CropFields to view the water information.": "<a href=\"{{href}}\">Abonner</a> på Mark&Bonde for at se vandoplysningerne.",
    "About CropFields": "Om M&B",
    "About the farm": "Om gården",
    "Account": "Konto",
    "AccountFeatures": "<li>Se mange år tilbage i sædskifte</li>\n<li>Ruteplanlægger og nøjagtige feltdata</li>\n<li>Se matrikeldata</li>\n<li>Se fler detaljer om satellitdata</li>\n<li>Højdekort over forskellige øjeblikke</li>\n<li>Opret et fakta (PDF)</li>\n<li>Eksportere feltdata (Shapefile)</li>\n<li>Filtrer felter efter alle egenskaber</li>\n<li>Udnyt alle tilgængelige Betafunktioner</li>",
    "Active sessions": "Aktive sessioner",
    "Add to list": "Tilføj til liste",
    "Add your own plots": "Tilføj dine egne parceller",
    "Address": "Adresse",
    "Agricultural area": "Landbrugsområde",
    "All": "Alt",
    "Altitude": "Højde",
    "Always know the exact cultivable area of fields for planning and invoicing": "Kend altid det korrekte dyrkbare areal af parceller til planer og fakturaer",
    "Analysis": "Analyse",
    "Apple": "Æble",
    "Arable land": "Agerjord",
    "Are you sure?": "Er du sikker?",
    "Area": "Overflade",
    "Asparagus": "Asparges",
    "Attention! This export only contains a part of the search result": "Bemærk! Eksporten indeholder kun en del af søgeresultatet",
    "Attributes": "Funktion",
    "Author": "Forfatter",
    "BETA": "BETA",
    "Back": "Tilbage",
    "Back to CropFields": "Tilbage til M&B",
    "Bans": "Leguminøse grøntsager",
    "Barley": "Byg",
    "Bean": "Bønne",
    "Beet": "Roer",
    "Berry": "Bær",
    "Beta": "Beta",
    "Birds and Habitats Directive": "Ramsar- og habitatområder",
    "Birds directive": "Ramsarområder",
    "Blog": "Blog",
    "Blog unavailable": "Blog ikke tilgængelig",
    "Broccoli": "Brocolli",
    "Buffer zone": "Bufferzone",
    "Building": "Bygning",
    "Bulb": "Blomsterløg",
    "By using this website you agree to the terms above": "Ved at bruge denne ansøgning accepterer du ovenstående betingelser",
    "Cabbage": "Kål",
    "Cadastral field": "Matrikelplot",
    "Cadastre": "Matrikel",
    "Cadastre data unknown": "Kadasterdata ukendt",
    "Cancel": "Afbestille",
    "Capsicum": "Paprika",
    "Carrot": "Gulerod",
    "Cauliflower": "Blomkål",
    "Cherry": "Kirsebær",
    "Chicory": "Cikorie",
    "Chicory root": "Cikorie rod",
    "Choose file": "Vælg fil",
    "City": "Bopæl",
    "Clay": "Ler",
    "Clay-Loam": "Ler-ler",
    "Clear filters": "Ryd filtre",
    "Click here": "Klik her",
    "Click on the map to expand the measurement": "Klik på kortet for at udvide målingen",
    "Click on the map to expand the measurement, or click on the last point to finish the measurement": "Klik på kortet for at udvide målingen, eller på det sidste punkt for at stoppe målingen",
    "Click to start measurement": "Klik for at begynde måling",
    "Close": "Luk",
    "Clover": "Kløver",
    "Company": "Virksomhed",
    "Configure settings": "Konfigurer indstillinger",
    "Connect to RVO": "Opret forbindelse til RVO",
    "Contact": "Kontakt",
    "Continue without cookies": "Fortsæt med kun nødvendige cookies",
    "Coordinates": "Koordinater",
    "Corn": "Majs",
    "Could not refresh": "Kunne ikke opdatere",
    "Country choice": "Land valg",
    "Create a free account or login to use this functionality": "Opret en gratis konto eller log ind for at bruge denne funktionalitet",
    "Create an account": "Opret konto",
    "Create and share your own field lists": "Opret og del dine egne lister over felter",
    "Create field report": "Opret en rapport",
    "Create prescription maps with Cloudfarm": "Opret receptkort med cloudfarm",
    "Crop": "Afgrøde",
    "Crop Predictor": "Afgrødeforudsiger",
    "Crop per year": "Afgrøde pr. år",
    "Crop rotation": "Sædskifte",
    "CropFields": "mark&bønde",
    "CropFields blog": "Mark&Bonde blog",
    "CropFields crop prediction for {{year}}": "M&B afgrøde forudsigelse for {{year}}",
    "CropFields link": "Mark&bønde link",
    "Cucumber": "Agurk",
    "Custom lists": "Brugerdefinerede lister",
    "Dacom is building a number of new features for CropFields. Anyone with a PRO or higher can try out these features for themselves.": "Dacom bygger en række nye funktioner til CropFields. Alle med en PRO eller højere kan prøve disse funktioner selv.",
    "Deficit": "Underskud",
    "Delete RVO account": "Slet RVO-konto",
    "Discover": "Opdage",
    "Ditches": "Grøfter",
    "Ditches on map": "Grøfter på kortet",
    "Do you want more information about the Pro subscription? Then click <a href=\"{{url}}\">here</a>.": "Ønsker du flere oplysninger om Pro-abonnementet? Klik derefter <a href=\"{{url}}\">her</a>.",
    "Do you want to use CropFields with multiple users in your company? Please contact us via <a href=\"mailto:info@dacom.nl\">info@dacom.nl</a>.": "Vil du bruge Mark&Bonde med flere brugere i din virksomhed? Kontakt os via <a href=\"mailto:info@dacom.nl\">info@dacom.nl </a>.",
    "Download Geotiff": "Hent Geotiff",
    "Download Shapefile": "Hent Shapefile",
    "Drainage map": "Dræningskort",
    "Edit": "Redigere",
    "Edit list": "Rediger liste",
    "Edit my profile": "Rediger min profil",
    "Edit name": "Rediger navn",
    "Edit plots": "Redigere parceller",
    "Edit your profile and choose your settings.": "Rediger din profil, og vælg dine indstillinger.",
    "Egg plant": "Aubergine",
    "Email": "E-mail",
    "Empty": "Brak",
    "Enter a valid email address": "Indtast en gyldig e-mail-adresse",
    "Enter company name": "Indtast dit firmanavn",
    "Enter your name": "Indtast dit navn",
    "Erosion": "Erosion",
    "Erosion sensitivity": "Erosionsfølsomhed",
    "Error occurred": "Der er sket en fejl",
    "Export capacity after this export": "Resterende antal marker efter denne eksport",
    "Export field data to shape file": "Eksporter data til Shapefile",
    "Export {{count}} fields": "Eksporter {{count}} felter",
    "Exported field count": "Tidligere ekporterede marker",
    "Exported fields": "Eksporterede marker",
    "Facts": "Fakta",
    "Failed to change account": "Kontoændring mislykkedes",
    "Failed to create favorite": "Kunne ikke lave en favorit",
    "Failed to delete selected list": "Kunne ikke slette den valgte liste",
    "Failed to edit name": "Kunne ikke ændre navn",
    "Failed to fetch farm plots": "Downloadning af gårde gårde mislykkedes",
    "Failed to fetch farms": "Kunne ikke hente gårde",
    "Failed to make farm public": "Kunne ikke offentliggøre gården",
    "Failed to make list public": "Listen kunne ikke offentliggøres",
    "Failed to send mail, sorry": "Afsendelse af mail mislykkedes, undskyld",
    "Failed to show shared field": "Kunne ikke vise delt felt",
    "Failed to show shared list": "Kunne ikke vise delt liste",
    "Farm": "Bonde",
    "Farm overview": "Farm oversigt",
    "Farm profile": "Bedriftsprofilen",
    "Favorite": "Favorit",
    "Feedback": "Feedback",
    "Fetch data": "Hent data",
    "Fetching data": "Data hentes",
    "Field": "Mark",
    "Field export capacity": "Resterende antal marker til eksport",
    "Field export quota": "Eksporter pladsområder",
    "Field report": "Rapport",
    "Field selection": "Markvalg",
    "Field selection tool": "Markeringsværktøj",
    "Field size": "Overflade",
    "Fields": "Marker",
    "Fields in this export": "Marker in denne eksport",
    "Filter": "Filter",
    "Find and quickly navigate to fields": "Find og naviger hurtigt til felter",
    "Find suitable land for renting or purchasing": "Find passende marker til leje eller køb",
    "Flower": "Blomst",
    "Focus area nitrate": "Fokusområde nitrat",
    "Follow on social media": "Følg med på de sociale medier",
    "Follow satellite data": "Følg oplysningerne om satellitdata",
    "For more information, see": "For mere information se",
    "For only €16/month you will get all the functionalities of CropFields Pro again": "For kun €16/måned får du alle funktionerne i Mark&Bonde Pro igen ",
    "Forbidden": "Forbudt",
    "Forbidden irrigation": "Vandingsforbud",
    "Forest": "Skov",
    "Free Pro": "Gratis Pro",
    "Free Pro expired": "Gratis Pro er udløbet",
    "Free trial": "Gratis prøveversion",
    "French bean": "Fransk bønne",
    "Fruit": "Frugt",
    "GPS coordinates": "GPS koordinater",
    "Government property": "Statens ejendom",
    "Grain": "Korn",
    "Grape": "Drue",
    "Grass": "Græs",
    "Grass land": "Græsarealer",
    "Ground lease": "Grundleje",
    "Ground water": "Grundvand",
    "Ground water protection": "Grundvandsbeskyttelse",
    "Ground water protection area": "Grundvandsbeskyttelsesområde",
    "Growth": "Vækst",
    "Growth course": "Vækstkursus",
    "Growth potential": "Vækstpotentiale",
    "Growth with satellite images": "Vækst via satellitbilleder",
    "Habitat directive": "Habitatområder",
    "Head cabbage": "Hovedkål",
    "Height maps measured on different times": "Elevation kort af forskellige øjeblikke",
    "Hemp": "Hamp",
    "Herbs": "Krydderier",
    "I accept": "Jeg er enig",
    "I want to share {{subject}}": "Jeg vil dele {{subject}}",
    "If you have any questions or comments, email us at": "Hvis du har spørgsmål eller kommentarer, kan du sende os en e -mail på",
    "Infection chance": "Infektions chance",
    "Infection chance potato fungus": "Infektionsmulighed kartoffelsvamp",
    "Infection radar": "Infektionsradar",
    "Initialising": "Initialisere",
    "Institute": "Institut",
    "Irrigation limit": "Vandingsgrænse",
    "It concerns the first {{count}} fields measured from the center of the map (GPS location {{gps}})": "Det vedrører de første {{count}} felter målt fra midten af kortet (GPS -placering {{gps}})",
    "Large-small": "Stort-lille",
    "Layers": "Lag",
    "Lease": "Leje",
    "Lease list": "Lejeliste",
    "Lease status unknown": "Lejestatus ukendt",
    "Leek": "Purre",
    "Leeks": "Porre",
    "Less": "Mindre",
    "Lettuce": "Salat",
    "Liked fields": "Gillede felter",
    "Limited": "Begrænset",
    "List from {{name}}": "Liste fra {{name}}",
    "List has been uploaded": "Listen er blevet uploadet",
    "Lists": "Lister",
    "Lists overview": "Oversigt over lister",
    "Little shadow": "Lille skygge",
    "Live preview": "Live forhåndsvisning",
    "Loading": "Indlæser",
    "Loading blog": "Indlæser blog",
    "Loam": "Muld",
    "Loamy Sand": "Ler-sand",
    "Location": "Placering",
    "Location not found": "Din placering blev ikke fundet",
    "Log out": "Log ud",
    "Logged in as": "Logget ind som",
    "Login": "Log ind",
    "Login or create an account": "Log ind eller opret en konto",
    "Login to like farms and plots": "Log ind for at kunne lide gårde og bunde.",
    "Long lease": "Langtidslejemål",
    "Mail service@dacom.nl": " Mail service@dacom.nl",
    "Make farm profile public": "Gør bedriftsprofilen offentlig",
    "Make one of my lists public": "Gør en af mine lister offentlig",
    "Make selection smaller": "Gør valget mindre",
    "Make your farm and fields visible for all CropFields users.": "Gør din gård tilgængelig på M&B. Flere besøgende, kontakt til andre landmænd med mere.",
    "Manage public profile": "Gør offentlig",
    "Manage sessions": "Administrer sessioner",
    "Map": "Kortet",
    "Max annual fields export": "Max antal marker til eksport",
    "Max fields in single export": "Max antal marker som kan eksporters af gangen",
    "Maximum reached, upgrade to Premium": "Maksimum nået, opgrader til Premium",
    "Measure distance": "Mål afstand",
    "Message": "Besked",
    "Millet": "Hirse",
    "More": "Mere",
    "More information": "Mere information",
    "More information about our subscriptions": "Læs mere om vores abonnementer",
    "More news": "Flere nyheder",
    "More years": "Flere år",
    "Much shadow": "Meget skygge",
    "Municipality": "Kommune",
    "My lists": "Mine lister",
    "Name": "Navn",
    "Natura 2000": "Natura 2000",
    "Nature": "Natur",
    "Nature conservation": "Naturforvaltning",
    "New list": "Ny liste",
    "Next page": "Næste side",
    "Nitrate": "Nitrat",
    "Nitrate area": "Nitratområde",
    "No CropFields subscription yet": "Ingen M&B abonnement endnu",
    "No data": "Ingen data",
    "No data available, please try again later": "Der er ingen tilgængelige data på nuværende tidspunkt. Prøv igen senere.",
    "No information found for this field": "Der blev ikke fundet nogen oplysninger for denne plot",
    "No nature data known": "Ingen natur kendt",
    "No potatoes for {{year}} years": "Ingen kartofler i {{year}} år",
    "No results": "Ingen resultater",
    "No shadow": "Ingen skygge",
    "No soil data available": "Ingen jorddata tilgængelige",
    "Not within Natura 2000": "Falder ikke ind under Natura 2000",
    "Number in group": "Nummer i gruppe",
    "Nut": "Notat",
    "Oats": "Havre",
    "Onion": "Løg",
    "Or use our contact form under": "Eller brug vores kontaktformular under",
    "Order Pro": "Bestille Pro",
    "Order a subscription": "Bestil abonnement",
    "Organic": "Økologisk",
    "Orthophoto": "Luftfoto",
    "Other attributes": "Andre funktioner",
    "Other lease": "Andet lejemål",
    "Overlap": "Overlapning",
    "Owned": "Ejendom",
    "Ownership": "Ejendom",
    "PRO subscribers can configure additional features here": "PRO-abonnenter kan konfigurere yderligere funktioner her",
    "Page not found": "Siden blev ikke fundet",
    "Pasture": "Græsarealer",
    "Pea": "Ært",
    "Pear": "Pære",
    "Percentage": "Procent",
    "Period": "Periode",
    "Permanent grassland": "Permanent græsareal",
    "Photos": "Fotos",
    "Pine tree": "Nåletræ",
    "Plan": "Plan",
    "Plans": "Planer",
    "Plot name": "Feltnavn",
    "Plots": "Marker",
    "Potato": "Kartoffel",
    "Potato limit": "Kartoffelgrænse",
    "Precipitation deficit": "Nedbør underskud",
    "Preliminary": "Indledende",
    "Premium": "Premium",
    "Prescription map": "Receptkort",
    "Previous page": "Forrige side",
    "Print field": "Udskriv felt",
    "Pro": "Pro",
    "Profile": "Profil",
    "Province": "Region",
    "Public": "Offentlig",
    "Public Plots": "Offentlige marker",
    "Pumpkin": "Græskar",
    "RVO account valid until": "RVO-konto gyldig til",
    "Radar": "Radar",
    "Radish": "Radise",
    "Random": "Arbitrært",
    "Rapeseed": "Rapsfrø",
    "Read more": "Læs mere",
    "Reed": "Reed",
    "Reet": "Reed",
    "Refresh": "Opdater",
    "Refresh RVO data": "Opdater RVO-data",
    "Refresh own data": "Opdater egne data",
    "Reg soil type": "Ground. gødning lov",
    "Regulatory": "Regulatorisk",
    "Regulatory soil type": "Regulerende jordtype",
    "Remove": "Fjerne",
    "Report": "Faktaark",
    "Reset all filters": "Nulstil alle filtre",
    "Review many years with a subscription": "Gennemgå mange år med et abonnement",
    "Rice": "Ris",
    "Rose": "Rose",
    "Route planner and precise field information": "Ruteplanlægning og nøjagtige markdata",
    "Rye": "Rug",
    "Rye-grass": "Ruggræs",
    "Sand": "Sand",
    "Sandy Clay": "Sandet ler",
    "Sandy Clay-Loam": "Sandet ler-ler",
    "Sandy Loam": "Sandet lerjord",
    "Satellite image date": "Dato satellitbillede",
    "Search": "Søg",
    "Search fields by attribute throughout the country": "Søg plots af karakteristiske over hele landet",
    "Search filter": "Søg filter",
    "Search filter exports": "Eksport fra søgefilter",
    "Search for address, city, zip, GPS or XY": "Søg efter adresse, by, postnummer, GPS/XY",
    "See <a href=\"{{href}}\">{{description}}</a> for more information": "Se <a href=\"{{href}}\">{{description}}</a> for at få flere oplysninger",
    "Select a list to view, add or remove the plots in it.": "Vælg en liste for at se, tilføje eller fjerne felterne i den.",
    "Selected list was deleted": "Den valgte liste blev slettet",
    "Selected plots": "Udvalgte parceller",
    "Selection": "Udvælgelse",
    "Send": "Indsend",
    "Send a question or remark": "Send et spørgsmål eller en kommentar",
    "Sensitivity": "Følsomhed",
    "Session logged out due to inactivity or remote logout": "Session logget af på grund af inaktivitet eller fjernlogout",
    "Set address": "Indstil din adresse",
    "Settings": "Indstillinger",
    "Shadow": "Skygge",
    "Shadow map": "Skygge kort",
    "Share": "Dele",
    "Share {{subject}}": "Del {{subject}}",
    "Shared list": "Delt liste",
    "Short lease": "Korttidslejemål",
    "Show drainage lines": "Viser drænledninger",
    "Show less": "Vise mindre",
    "Show shadows": "Vis skygge kort",
    "Show soil potential": "Viser jordpotentiale",
    "Show trenches": "Vis skyttegrave",
    "Silt": "Silt",
    "Silty Clay-Loam": "Siltig ler-ler",
    "Small-large": "Lille-stort",
    "Socials": "Socials",
    "Soil Potential Map": "Jordpotentialekort",
    "Soil type": "Jordtype",
    "Some shadow": "Noget skygge",
    "Source": "Kilde",
    "Specialty filter": "Specialfilter",
    "Spinach": "Spinat",
    "Start with precision farming, with insight into altitude maps and satellite images": "Start præcisionslandbrug med højdekortindsigt",
    "Stop editing": "Stop med at redigere",
    "Strawberry": "Jordbær",
    "Subscribe": "Tilmeld",
    "Subscribe for all filter options": "Tag et abonnement på alle filterindstillinger",
    "Subscribe for filtering on <i>{{attr}}</i>": "Abonner på <i>{{attr}}</i> for at filtrere",
    "Subscribe to CropFields to view all satellite images": "Tag et abonnement for at se alle satellitbillederne på kortet",
    "Subscribe to use all options": "Tag et abonnement for at se alle muligheder",
    "Subscribe to use this functionality": "Tag et abonnement for at bruge denne funktionalitet",
    "Subscribe to view all available height data sources": "Tag et abonnement for at se højden af ​​alle tilgængelige år",
    "Subscribe to view the exact area": "Tag et abonnement for at se den nøjagtige overflade",
    "Subscribe to view this data. CropFields is a website for farmers. It is used by many users for free. This is great, but we would like to make the site even better. Help us by <a href=\"{{url}}\">subscribing to CropFields</a>.": "Abonner for at se disse data. Mark&Bonde er et websted for landmænd. Det bruges af mange brugere gratis. Dette er fantastisk, men vi vil gerne gøre siden endnu bedre. Hjælp os ved <a href=\"{{url}}\">abonnement på Mark&Bonde</a>.",
    "SubscribeToSeeSharedData": "<a href=\"{{href}}\"> Abonner </a> på M&B for at se det delte udvalg. Hvis du allerede har et abonnement, skal du logge ind og aktivere feltet markeringsværktøj under Indstillinger.",
    "Subscription": "Abonnement",
    "Subscriptions": "Abonnementer",
    "Sugar beet": "Sukkerroer",
    "Summer rye": "Sommer rug",
    "Sunflower": "Solsikke",
    "Sustainable lease conditions": "Bæredygtige lejeforhold",
    "Switch to": "Skifte til",
    "Tel +31 88 3226600": "-",
    "Tell me more about the subscriptions": "Oplysninger om abonnementer",
    "Terms": "Betingelser",
    "TextAllImagesPro": "Tag et abonnement for at se et tilgængeligt billede på kortet. Uden Mark&Bonde Pro vises kun et (forholdsvis nyligt) demobillede på kortet. Med et abonnement kan alle 'prikker' klikkes, og hvert billede pr. Plot kan ses på kortet.",
    "TextBetterUse": "at gøre bedre brug af Mark&Bonde. Med et abonnement kan du spare meget for senere, se lag på højt niveau og bruge omfattende søgefunktionalitet. Du kan også udskrive og eksportere marker til shapefile og andre formater.",
    "TextCookies": "Derudover bruger Mark&Bonde cookies. Vi bruger cookies til at opbevare statistik (anonymiseret baseret på IP-adresse) og til at optimere brugeroplevelsen. Cookies deles ikke med tredjeparter, og efter {{cookieDays}} dage slettes cookies automatisk. Læs mere om dette i <a href=\"{{termsAndConditionsUrl}}\">vores vilkår og betingelser og <a href=\"{{privacyStatementUrl}}\">fortrolighedserklæring</a>.",
    "TextDownloadSearchFilter": "Download markerne fra dit søgefilter. Markerne bliver presentered i en excel- og en shapefile samen med deres forskellige egenskaber.",
    "TextFieldsInView": "Så du har altid de interessante plots i syne med et klik",
    "TextLetUsKnow": "Lad os høre, hvad du synes om Mark&Bonde. Den videre udvikling af Mark&Bonde er drevet af de mange spørgsmål fra brugerne. Har du et spørgsmål eller en kommentar? Vi vil gerne høre det.",
    "TextLikeFields": "Her er et overblik over plots, som du følger. For at følge en marker kan du vælge en på kortet og klikke på hjertet",
    "TextNationalLayer": "Med et Mark&Bonde-abonnement kan du se {{layer}} på nationalt niveau med attributter i en Excel-fil og en Shapefile.",
    "TextSettingsInfo": "Konfigurer dine indstillinger her. Som betalt bruger kan du aktivere specifikke funktioner og få mest muligt ud af siden. Prøv dem en efter en, og lad os vide, hvad du synes om",
    "TextTerms": "Mark&Bonde visualiserer åbne datasæt som er relevante for landbrug. Oplysingerne kommer fra forskillige offentligt tilgængelige åbne kilder. Dacom kan ikke give nogen garanti for kvaliteten eller nøjagtigheden af dataene. Vi visualisere oplysningerne efter vores bedste evne. For rigtigheden og aktualiteten af dataene skal kildeholderen kontaktes. Vi henviser hvor muligt til kildens indehavere under",
    "TextViewedTooManyPlots": "Du har allerede set {{maxFields}} afgrødefelter gratis i denne uge!Vi arbejder hårdt på at holde Mark&Bonde reklamefri og opdateret for at give god service, og vi fortsætter med at opdatere og tilføje relevante datasæt. Som et resultat, vi har valgt at give professionelle brugere et Pro-abonnement for 13 € pr. måned.",
    "TextViewedTooManyPlotsNoCookies": "Vi bestræber os meget på at holde vores uafhængige platform Mark&Bonde reklamefri og opdateret, at levere god service og levere mere relevante datasæt. Hjælp os som professionel bruger med at abonnere på et Pro-abonnement til € 13 pr. Måned.",
    "TextWithAProSubscription": "<p>Med et Pro-abonnement kan du</p><ul><li>Find hurtigt felter og naviger der</li><li>Kend den nøjagtige dyrkbare overflade af afgrødefelter</li><li>Forstå afgrødedrejninger pr. plot til planlægning af en beskæringsplan</li><li>Brug højdekort og satellitbilleder til præcisionslandbrug</li><li>Brug matrikeloplysningerne til at finde passende jord til leje eller til salg </li></ul>",
    "Thanks for you message": "Tak for din besked",
    "The farm": "Gården",
    "The list is empty.": "Listen er tom.",
    "The public state of your farm has been changed": "Din gårds offentlige tilstand er blevet ændret",
    "The public state of your list has been changed": "Din listes offentlige tilstand er blevet ændret",
    "This field could not be found!": "Dette felt kunne ikke findes!",
    "This field is located within the boundaries of nature collective": "Dette felt ligger indenfor naturkollektivets grænser",
    "This field is part of habitats": "Denne mark er en del af levesteder",
    "This field is part of the Nature Network of this country": "Denne grund er en del af dette lands naturnetværk",
    "This field is used as <i>{{useTitle}}</i>": "Dette felt bruges som <i>{{useTitle}}</i>",
    "This is a pro feature": "Dette er en pro-funktion",
    "This plot is owned by state institute <i>{{name}}</i>": "Denne grund ejes af statens institut <i>{{name}}</i>",
    "This session has expired or has been terminated remotely with a different login": "Denne session er udløbet eller afsluttet, fordi denne konto er logget ind på for mange enheder",
    "Title": "Titel",
    "Today": "I dag",
    "Tomato": "Tomat",
    "Total": "i alt",
    "Tree": "Træ",
    "Trenches": "Skyttegrave",
    "Tulip": "Tulipan",
    "Unknown": "Ukendt",
    "Unselect plots": "Fravælg mark",
    "Upload": "Upload",
    "Uploading": "Uploader",
    "Uploading this file will overwrite your current lease list": "Upload af denne fil vil overskrive din nuværende leasingliste",
    "Use title": "Brug titel",
    "Vegetables": "Vegetabilsk",
    "Video": "Video",
    "View all datasets": "Se alle datasæt",
    "View cadastral data": "Se cadastral data",
    "View crop rotations for creating a healthy cultivation plan": "Se afgrødeskifter for at skabe en sund afgrødeplan",
    "View many historic crop rotation years": "Se tilbage mange år i afgrødehistorie",
    "View {{subject}} on CropFields": "Se {{subject}} på mark&bønde",
    "WDVI images": "WDVI-billeder",
    "Water board": "Vandbræt",
    "Water trap": "Vandtrappe",
    "Website": "Internet side",
    "With a crop recording subscription, you can work with multiple users on the same company & subscription": "Med et afgrøderegistreringsabonnement kan du arbejde med flere personer på samme firma og abonnement",
    "With a free account you can use many extra functionalities:": "Med en gratis konto kan du bruge mange ekstra funktioner:",
    "With the free version of CropFields, you can view the basic data of a plot such as crop group, location and agricultural area.": "Med den gratis version af Mark&Bonde kan du se de grundlæggende data for et plot som afgrøde, placering og landbrugsområde.",
    "Without a subscription, you can view data of {{maxFields}} crop fields per week for free.": "Uden et abonnement kan du se data fra {{maxFields}} afgrøde felter om ugen gratis.",
    "Would you like to view additional crop fields?": "Vil du se flere felter?",
    "XY coordinates": "XY koordinater",
    "Yes, make my farm profile public": "Ja, gør min gårdprofil offentlig",
    "Yes, make one of my lists public": "Ja, gør en af mine lister offentlig",
    "You can be logged in on up to two devices": "Du kan være logget ind på samme konto med maksimalt to enheder",
    "Your contact information is public to other users.": "Dine kontaktoplysninger er offentlige for andre brugere.",
    "Your one-month trial subscription has expired": "Dit prøveabonnement er udløbet",
    "Your question or remark": "Dit spørgsmål eller din kommentar",
    "Zip code": "Postnummer",
    "Zipcode/city": "Postnummer / sted",
    "all images": "alle billeder",
    "average": "gennemsnit",
    "avg": "gns",
    "by": "ved",
    "crop rotation": "præfrugt fra tidligere år",
    "difference": "forskel",
    "different attributes": "forskellige egenskaber",
    "different soil types": "forskellige jordtyper",
    "error": "fejl",
    "example": "eksempel",
    "fields": "marker",
    "filterTextAccount": "Opret en konto, eller log ind for at bruge alle filterindstillinger",
    "get a free trial subscription to CropFields": "få et gratis prøveabonnement på Mark&Bonde",
    "heighest": "højeste",
    "l_cadastre_p1": "1234 1c",
    "l_cadastre_p2": "1234 2b",
    "l_info_bufferzones": "En bufferstribe er en stribe jord, hvor du ikke må bruge gylle, kemiske plantebeskyttelsesmidler eller biocider. Dette beskytter vandkvaliteten og stimulerer biodiversiteten. En bufferstribe ligger langs et vandløb, der ligger langs landbrugsjord.",
    "l_info_ews": "Infektionsradar giver dig mulighed for at opdage overhængende risici for dine afgrøder på grund af sygdom.",
    "l_info_facts": "Grundens grænser er samlet fra nationale kilder, der er forbundet med den fælles landbrugspolitik (CAP). Grænserne er baseret på brug til den fælles landbrugspolitik, ikke ejerskab.",
    "l_info_growth": "Aktuelt vegetationskort baseret på  satellitbilleder. For hver mark hvises såning og høst.<br />Kilde: <a href=\"https://copernicus.eu/\">copernicus.eu</a>",
    "l_info_height": "Digitalt højdekort. Et Mark&Bonde-abonnement muliggør flere versioner.",
    "l_info_lease": "Udbyder du dette felt til udlejning og vil du fortælle, hvordan du stimulerer bæredygtige aktiviteter? Se <a href=\"/page/lease\">leasingkontrakt</a> for at få flere oplysninger.",
    "l_info_nature": "Natura 2000-områderne er udpeget for at beskytte en række truede, sårbare eller karakteristiske dyr, fugle, planter og naturtyper. Kilde: <a href=\"https://ec.europa.eu/environment/nature/natura2000/index_en.htm\">Natura 2000 i Europa</a>",
    "l_info_property": "Matrikelregistret indeholder oplysninger om alle matrikelnumre",
    "l_info_soil": "Jordklassificering baseret på lokale kort. Hver region definerer deres egen jordklassificeringsstrategi.",
    "l_info_use_title": "Her viser vi brugstitlen, som regeringen har indsamlet den i den kombinerede opgørelse. Disse data er IKKE kontrolleret af regeringen, de er vejledende, og der kan ikke udledes rettigheder herfra. Der er et misforhold mellem regeringsgrunde og matrikelafsnittene der har en specifik brugstitel.",
    "l_info_water": "Grøfter og vandveje",
    "l_info_weather": "Nedbør underskud",
    "legend": "legende",
    "length": "længde",
    "lowest": "laveste",
    "max": "max",
    "meter": "meter",
    "min": "minus",
    "more years": "flere år",
    "nr": "nr",
    "or": "eller",
    "province and municipality borders": "provins- og kommunegrænser",
    "restrictedAccount": "Opret en gratis konto eller log ind for at bruge denne funktionalitet",
    "settings_WDVI_images": "En ulempe ved NDVI er, at satellitbilleder senere på sæsonen ikke længere kan se forskel på en fuld og en meget fuld afgrøde. WDVI -billeder lider mindre af dette. Dette vækstindeks ser også på refleksionen af den bare jord. Dette forbedrer kontrasten.",
    "settings_crop_predictor_feature_warning": "Denne funktion er deaktiveret et stykke tid. Lad os vide, hvis du synes, at dette er en relevant mulighed, og det vil rykke op på prioriteringslisten.",
    "settings_crop_predictor_hanze": "I samarbejde med Hanze University Groningen har Dacom udviklet en model, som fremtidige afgrøder kan forudsiges, Crop Predictor.",
    "settings_crop_predictor_info_1": "Slå skifteren til, så du for hvert afgrødeplot i Mark&Bonde kan se, hvilke afgrøder vi forudsiger for hvert plot i 2021 baseret på tidligere afgrøderotationer og aktuelle satellitbilleder.",
    "settings_crop_predictor_info_2": "Ved hjælp af deep learning AI og algoritmer afdækker modellen underliggende mønstre i datasættet. Input fra modellen er i første omgang baseret på afgrøde rotation af alle hollandske pakker som registreret i Basis Registratie Percelen for perioden fra 2005 til 2020. Prognosen for 2021 vil fortsat forbedre i løbet af året, efterhånden som nye satellitbilleder bliver tilgængelige. komme. Modellen ser f.eks. På NDVI -vækstkurven for et plot og sammenligner det med tidligere år.",
    "settings_crop_predictor_info_3": "Efter at have slået kontakten til, viser vi på CropFields tre afgrøder, der sandsynligvis er i henhold til modellen for hvert plot. En høj procentdel for en afgrøde betyder, at modellen tidligere har observeret et sådant mønster i de hollandske parceller, og at modellen antager, at denne afgrøde sandsynligvis vil være i 2021.",
    "settings_ditches": "Skyttegrave og vandveje",
    "settings_drainage_map": "Dræningskortet er baseret på det mest aktuelle elevationskort. Du kan se, hvor vandet flyder. Du kan f.eks. bruge dette kort til at analysere erosion på dit plot eller til at grave grøfter, hvis du har oversvømmelser.",
    "settings_ews_feature": "Med Dacoms Infektionsradar modtager du rettidig information om infektionsrisikoen for omkring 20 svampesygdomme. Dacom -modellerne dækker således over de vigtigste dyrkede afgrøder i Holland. I første omgang starter vi i det små, men flere og flere funktionaliteter tilføjes, efterhånden som sæsonen skrider frem. Hold øje med fremskridtet ved regelmæssigt at klikke på Radar i venstre menulinje i Mark&Bonde.",
    "settings_field_selection_tool": "Som landmand bliver du ofte bedt om at dele dine parceller. Med det nye markvalg-værktøj kan du nu også dele flere felter på én gang. Klik på mark-ikonet øverst til højre, og derefter kan du klikke på flere felter for at sammensætte en praktisk liste. Send derefter via WhatsApp, Twitter, Facebook eller din e-mail. Modtageren vil se nøjagtig den samme liste i Mark& Bonde, som du har sendt. Hvis du vil, kan du også sende en Shape-fil med en praktisk Excel-fil.",
    "settings_growth_course": "Med vores nye Timeline kan du på et øjeblik se den rumlige variation af et plot over tid. Dette giver dig mulighed for at opdage afvigende steder i et felt endnu hurtigere eller vælge det rigtige satellitbillede til et opgavekort.",
    "settings_lease": "Del selv information om leje af en grund.",
    "settings_precip_deficit": "Nedbørsunderskuddet visualiserer, hvor tørt det er forskellige steder i landet.",
    "settings_shadow_map": "Skyggekortet angiver på hver mark, hvor meget skygge der falder på plottet i vækstsæsonen. Du kan f.eks. bruge disse oplysninger til at justere rækkeafstanden i de skraverede områder eller bagefter analysere, hvorfor udbyttet på bestemte dele af plottet halter bagefter resten.",
    "settings_soil_potency_map": "Med Jordens potenskort viser vi, hvilke dele af et plot der strukturelt fungerer bedre eller mindre. Det gør vi ved at overlejre satellitbilleder fra de sidste 5 år. På denne måde opdager vi mønstre og fjerner årstidens påvirkninger. Du kan f.eks. bruge dette kort til at lave en befrugtningsplan eller se, om der er behov for foranstaltninger, der kan forbedre jordens struktur.",
    "show trenches, ditch sides": "vis skyttegrave, grøftesider",
    "subscribe here to CropFields": "Tag et Mark&Bonde abonnement her",
    "the CropFields subscription": "mark&bonde abonnement",
    "the nature collective": "naturkollektivet",
    "this blog": "denne blog",
    "this farm": "Denne gård",
    "this field": "dette plot",
    "this field selection": "dette markvalg",
    "type": "type",
    "waterways": "vandveje",
    "{{days}} days remaining": "{{days}} dage tilbage",
    "{{fields}} Fields": "{{fields}} Marker",
    "{{hitsCount}} results": "{{hitsCount}} resultater",
    "{{percentage}}% chance on {{subject}}": "{{percentage}}% chance for {{subject}}"
}},
"de": {translation: {
    "<2ha, 2-4ha, 4-10ha and 10+ ha": "<2 ha, zwischen 2-4 ha, 4 bis 10 ha und 10+ ha",
    "<a href=\"/authorize\">Login</a> to CropFields to view the cadastral field mapping": "<a href=\"/authorize\">Melden Sie sich</a> bei Bauer&Grund an, um die Katasterfeldkartierung anzuzeigen",
    "<a href=\"{{href}}\">Subscribe</a> to CropFields to view the cadastral field mapping": "<a href=\"{{href}}\">Abonnieren</a> Sie Bauer&Grund, um die Katasterfeldkartierung anzuzeigen",
    "<a href=\"{{href}}\">Subscribe</a> to CropFields to view the water information.": "<a href=\"{{href}}\">Abonnieren</a> Bauer&Grund, um die Wasserinformationen anzuzeigen.",
    "About CropFields": "Über Bauerngrund",
    "About the farm": "Über den Hof",
    "Account": "Konto",
    "AccountFeatures": "<li>Rückblick auf viele Jahre in der Fruchtfolge des Feldes</li>\n<li>Routenplaner und genaue Felddaten</li>\n<li>Katasterdaten anzeigen</li>\n<li>Weitere Details zu den Satellitenbildern ansehen</li>\n<li>Höhenkarten verschiedener Jahre</li>\n<li>Factsheet erstellen (PDF)</li>\n<li>Felddaten exportieren (Shapefile)</li>\n<li>Felder nach allen Merkmalen filtern</li>\n<li>Alle verfügbaren Beta-Funktionen nutzen</li>",
    "Active sessions": "Aktive Sitzungen",
    "Add to list": "Zur Liste hinzufügen",
    "Add your own plots": "Fügen Sie Ihre eigenen Flurstücke hinzu",
    "Address": "Adresse",
    "Agricultural area": "Landwirtschaftliches Gebiet",
    "All": "Alles",
    "Altitude": "Höhe",
    "Always know the exact cultivable area of fields for planning and invoicing": "Stets die korrekte Anbaufläche der Schläge für die Planung und Fakturierung kennen",
    "Analysis": "Analyse",
    "Apple": "Apfel",
    "Arable land": "Ackerland",
    "Are you sure?": "Bist du sicher?",
    "Area": "Oberfläche",
    "Asparagus": "Spargel",
    "Attention! This export only contains a part of the search result": "Hinweis! Der Export enthält nur einen Teil des Suchergebnisses",
    "Attributes": "Merkmalen",
    "Author": "Autor",
    "BETA": "BETA",
    "Back": "Zurück",
    "Back to CropFields": "Zurück zu BauernGrund",
    "Bans": "Hülsenfruchtgemüse",
    "Barley": "Girste",
    "Bean": "Bohne",
    "Beet": "Zuckerrüben",
    "Berry": "Beer",
    "Beta": "Beta",
    "Birds and Habitats Directive": "Richtlinie über Vögel und Lebensräume",
    "Birds directive": "Vogelschutzrichtlinie",
    "Blog": "Blog",
    "Blog unavailable": "Blog nicht verfügbar",
    "Broccoli": "Brokkoli",
    "Buffer zone": "Pufferzone",
    "Building": "Gebäude",
    "Bulb": "Blumenzwiebel",
    "By using this website you agree to the terms above": "Mit dieser Anwendung akzeptieren Sie die oben genannten Bedingungen.",
    "Cabbage": "Kohl",
    "Cadastral field": "Flurstück",
    "Cadastre": "Grundbuch",
    "Cadastre data unknown": "Keine Kataster bekannt",
    "Cancel": "Abbrechen",
    "Capsicum": "Paprika",
    "Carrot": "Karotte",
    "Cauliflower": "Blumenkohl",
    "Cherry": "Kirsche",
    "Chicory": "Chicoree",
    "Chicory root": "Zichorienwurzel",
    "Choose file": "Datei wählen",
    "City": "Residenz",
    "Clay": "Ton",
    "Clay-Loam": "Ton-Lehm",
    "Clear filters": "Filter löschen",
    "Click here": "Klicken Sie hier",
    "Click on the map to expand the measurement": "Klicken Sie auf die Karte, um die Messung zu erweitern.",
    "Click on the map to expand the measurement, or click on the last point to finish the measurement": "Klicken Sie auf die Karte, um die Messung zu erweitern, oder auf den letzten Punkt, um die Messung zu stoppen",
    "Click to start measurement": "Klicken, um die Messung zu starten",
    "Close": "Schließen",
    "Clover": "Kleeblatt",
    "Company": "Firma",
    "Configure settings": "Einstellungen konfigurieren",
    "Connect to RVO": "Mit RVO verbinden",
    "Contact": "Kontakt",
    "Continue without cookies": "Weiter mit nur notwendigen Cookies",
    "Coordinates": "Koordinaten",
    "Corn": "Mais",
    "Could not refresh": "Konnte nicht aktualisiert werden",
    "Country choice": "Länderwahl",
    "Create a free account or login to use this functionality": "Erstellen Sie ein kostenlos Konto oder logge dich ein.",
    "Create an account": "Konto erstellen",
    "Create and share your own field lists": "Eigenen Schlaglisten erstellen und teilen",
    "Create field report": "Feldbericht erstellen",
    "Create prescription maps with Cloudfarm": "Applikationskarte mit Cloudfarm erstellen",
    "Crop": "Ernte",
    "Crop Predictor": "Erntevorhersage",
    "Crop per year": "Ernte pro Jahr",
    "Crop rotation": "Fruchtfolge",
    "CropFields": "Bauerngrund",
    "CropFields blog": "Bauerngrund Blog",
    "CropFields crop prediction for {{year}}": "Erntevorhersage von Landwirten für {{year}}",
    "CropFields link": "Bauerngrund Link",
    "Cucumber": "Gurke",
    "Custom lists": "Benutzerdefinierte Listen",
    "Dacom is building a number of new features for CropFields. Anyone with a PRO or higher can try out these features for themselves.": "Dacom entwickelt eine Reihe neuer Funktionen für Bauer&Grund. Jeder mit einem PRO oder höher kann diese Funktionen selbst ausprobieren.",
    "Deficit": "Defizit",
    "Delete RVO account": "RVO-Konto löschen",
    "Discover": "Entdecken",
    "Ditches": "Gräben",
    "Ditches on map": "Gräben auf der Karte",
    "Do you want more information about the Pro subscription? Then click <a href=\"{{url}}\">here</a>.": "Möchten Sie weitere Informationen zum Pro-Abonnement? Klicken Sie dann <a href=\"{{url}}\">hier</a>.",
    "Do you want to use CropFields with multiple users in your company? Please contact us via <a href=\"mailto:info@dacom.nl\">info@dacom.nl</a>.": "Sie möchten Bauer&Grund mit mehreren Benutzern in Ihrem Unternehmen nutzen? Bitte kontaktieren Sie uns über <a href=\"mailto:info@dacom.nl\">info@dacom.nl</a>.",
    "Download Geotiff": "Geotiff herunterladen",
    "Download Shapefile": "Shapefile herunterladen",
    "Drainage map": "Entwässerungskarte",
    "Edit": "bearbeiten",
    "Edit list": "Liste bearbeiten",
    "Edit my profile": "Profil bearbeiten",
    "Edit name": "Namen bearbeiten",
    "Edit plots": "Flurstücke bearbeiten",
    "Edit your profile and choose your settings.": "Bearbeiten Sie Ihr Profil und wählen Sie Ihre Einstellungen.",
    "Egg plant": "Aubergine",
    "Email": "Email",
    "Empty": "Leer",
    "Enter a valid email address": "Bitte geben Sie eine gültige E-Mail-Adresse ein",
    "Enter company name": "Geben Sie Ihren Firmennamen ein",
    "Enter your name": "Geben Sie Ihren Namen ein",
    "Erosion": "Erosion",
    "Erosion sensitivity": "Erosionsempfindlichkeit",
    "Error occurred": "Ein Fehler ist aufgetreten",
    "Export capacity after this export": "Verbleibender Speicherplatz nach diesem Export",
    "Export field data to shape file": "Felddaten in Shapefile exportieren",
    "Export {{count}} fields": "{{count}} Felder exportieren",
    "Exported field count": "Zuvor exportierte Felder",
    "Exported fields": "Exportierte Grunstücke",
    "Facts": "Merkmalen",
    "Failed to change account": "Kontowechsel fehlgeschlagen",
    "Failed to create favorite": "Favorit fehlgeschlagen",
    "Failed to delete selected list": "Ausgewählte Liste konnte nicht gelöscht werden",
    "Failed to edit name": "Name konnte nicht bearbeitet werden",
    "Failed to fetch farm plots": "Flurstücke konnten nicht von der Farm abgeholt werden",
    "Failed to fetch farms": "Fehler beim Abrufen von Farmen",
    "Failed to make farm public": "Farm konnte nicht veröffentlicht werden",
    "Failed to make list public": "Liste konnte nicht veröffentlicht werden",
    "Failed to send mail, sorry": "Fehler beim Senden der E-Mail, sorry",
    "Failed to show shared field": "Fehler beim Anzeigen des freigegebenen Felds",
    "Failed to show shared list": "Geteilte Liste konnte nicht angezeigt werden",
    "Farm": "Bauer",
    "Farm overview": "Farmübersicht",
    "Farm profile": "Farmprofil",
    "Favorite": "Favorit",
    "Feedback": "Rückmeldung",
    "Fetch data": "Daten abrufen",
    "Fetching data": "Daten werden abgerufen",
    "Field": "Plot",
    "Field export capacity": "Zu exportieren",
    "Field export quota": "Nog zu exportieren",
    "Field report": "Feld Datenblatt",
    "Field selection": "Feldauswahl",
    "Field selection tool": "Feldauswahl-Tool",
    "Field size": "Losgröße",
    "Fields": "Flurstücke",
    "Fields in this export": "Felder in diesem Export",
    "Filter": "Filter",
    "Find and quickly navigate to fields": "Finden und navigieren Sie schnell zu Feldern",
    "Find suitable land for renting or purchasing": "Geeignete Schläge zum Pachten oder Kaufen finden",
    "Flower": "Blume",
    "Focus area nitrate": "Fokusbereich Nitrat",
    "Follow on social media": "Folgen Sie in den sozialen Medien",
    "Follow satellite data": "Details von Satellitendaten verfolgen",
    "For more information, see": "Weitere Informationen finden Sie unter",
    "For only €16/month you will get all the functionalities of CropFields Pro again": "Für nur 13€/Monat erhalten Sie wieder alle Funktionaltäten von Bauer&Grund Pro",
    "Forbidden": "Verboten",
    "Forbidden irrigation": "Bewässerungsverbot",
    "Forest": "Wald",
    "Free Pro": "Kostenlos Pro",
    "Free Pro expired": "Kostenlos Pro forbei",
    "Free trial": "Kostenlose Testphase",
    "French bean": "Grüne Bohne",
    "Fruit": "Obst",
    "GPS coordinates": "GPS-Koordinaten",
    "Government property": "Staatseigentum",
    "Grain": "Korn",
    "Grape": "Traube",
    "Grass": "Gras",
    "Grass land": "Grünland",
    "Ground lease": "Erbpacht",
    "Ground water": "Grundwasser",
    "Ground water protection": "Grundwasserschutz",
    "Ground water protection area": "Grundwasserschutzgebiet",
    "Growth": "Wachstum",
    "Growth course": "Wachstumskurs",
    "Growth potential": "Wachstumspotential",
    "Growth with satellite images": "Wachstum über Satellitenbilder",
    "Habitat directive": "Habitatrichtlinie",
    "Head cabbage": "Kohl",
    "Height maps measured on different times": "Höhenkarten verschiedener Momente",
    "Hemp": "Hanf",
    "Herbs": "Krauter",
    "I accept": "Ich stimme zu",
    "I want to share {{subject}}": "Ich möchte {{subject}} teilen",
    "If you have any questions or comments, email us at": "Wenn Sie Fragen oder Kommentare haben, senden Sie uns eine E-Mail an",
    "Infection chance": "Infektionsgefahr",
    "Infection chance potato fungus": "Ansteckungsgefahr Kartoffelpilz",
    "Infection radar": "Infektionsradar",
    "Initialising": "Initialize",
    "Institute": "Institut",
    "Irrigation limit": "Bewässerungsgrenze",
    "It concerns the first {{count}} fields measured from the center of the map (GPS location {{gps}})": "Es betrifft die ersten {{count}} Felder, gemessen von der Kartenmitte (GPS-Standort {{gps}})",
    "Large-small": "Groß-Klein",
    "Layers": "Ebenen",
    "Lease": "Miete",
    "Lease list": "Mietliste",
    "Lease status unknown": "Mietstatus unbekannt",
    "Leek": "Lauch",
    "Leeks": "Lauch",
    "Less": "Weniger",
    "Lettuce": "Salat",
    "Liked fields": "Gefallene Felder",
    "Limited": "Begrenzt",
    "List from {{name}}": "Liste von {{name}}",
    "List has been uploaded": "Liste wurde hochgeladen",
    "Lists": "Listen",
    "Lists overview": "Listenübersicht",
    "Little shadow": "Kleiner Schatten",
    "Live preview": "Live Vorschau",
    "Loading": "Wird geladen",
    "Loading blog": "Blog laden",
    "Loam": "Lehm",
    "Loamy Sand": "Lehmiger Sand",
    "Location": "Ort",
    "Location not found": "Ihr Standort wurde nicht gefunden",
    "Log out": "Abmelden",
    "Logged in as": "Eingeloggt als",
    "Login": "Anmeldung",
    "Login or create an account": "Anmelden oder Konto erstellen",
    "Login to like farms and plots": "Loggen Sie sich ein, um Farmen und Parzellen zu mögen",
    "Long lease": "Langfristiger Mietvertrag",
    "Mail service@dacom.nl": "Mail service@dacom.nl",
    "Make farm profile public": "Farmprofil öffentlich machen",
    "Make one of my lists public": "Mache eine meiner Listen öffentlich",
    "Make selection smaller": "Auswahl verkleinern",
    "Make your farm and fields visible for all CropFields users.": "Machen Sie Ihre Farm auf B&G auffindbar. Mehr Besucher, Kontakt zu anderen Landwirten und mehr.",
    "Manage public profile": "Veröffentlichen",
    "Manage sessions": "Aktive Sitzungen",
    "Map": "Karte",
    "Max annual fields export": "Max. Export Grundstücke",
    "Max fields in single export": "Max. Export Grundstücke gleiczeitig",
    "Maximum reached, upgrade to Premium": "Maximal erreicht, Upgrade auf Premium",
    "Measure distance": "Abstand messen",
    "Message": "Nachricht",
    "Millet": "Millet",
    "More": "Mehr",
    "More information": "Mehr Informationen",
    "More information about our subscriptions": "Lesen Sie mehr über unsere Abonnements",
    "More news": "Weitere Neuigkeiten",
    "More years": "Mehr Jahre",
    "Much shadow": "Viel Schatten",
    "Municipality": "Gemeinde",
    "My lists": "Meine Listen",
    "Name": "Name",
    "Natura 2000": "Natura 2000",
    "Nature": "Natur",
    "Nature conservation": "Naturschutz",
    "New list": "Neue Liste",
    "Next page": "Vorherige Seite",
    "Nitrate": "Nitrat",
    "Nitrate area": "Nitratbereich",
    "No CropFields subscription yet": "Noch kein B&G-Abonnement",
    "No data": "Keine Daten",
    "No data available, please try again later": "Derzeit sind keine Daten verfügbar. Bitte versuchen Sie es später noch einmal.",
    "No information found for this field": "Für dieses Diagramm wurden keine Informationen gefunden.",
    "No nature data known": "Keine Natur bekannt",
    "No potatoes for {{year}} years": "Seit {{year}} Jahren keine Kartoffeln mehr",
    "No results": "Keine Ergebnisse",
    "No shadow": "Kein Schatten",
    "No soil data available": "Keine Bodendaten verfügbar",
    "Not within Natura 2000": "Fällt nicht unter Natura 2000",
    "Number in group": "Nummer in Gruppe",
    "Nut": "Nüs",
    "Oats": "Hafer",
    "Onion": "Zwiebel",
    "Or use our contact form under": "Oder nutzen Sie unser Kontaktformular unter",
    "Order Pro": "Pro bestellen",
    "Order a subscription": "Abonnieren",
    "Organic": "Organisch",
    "Orthophoto": "Luftbild",
    "Other attributes": "Andere Funktionen",
    "Other lease": "Sonstiges Mietverhältnis",
    "Overlap": "Überlappung",
    "Owned": "Eigentum",
    "Ownership": "Eigentum",
    "PRO subscribers can configure additional features here": "PRO-Abonnenten können hier zusätzliche Funktionen konfigurieren",
    "Page not found": "Seite nicht gefunden",
    "Pasture": "Grünland",
    "Pea": "Erbse",
    "Pear": "Birne",
    "Percentage": "Prozentsatz",
    "Period": "Zeitraum",
    "Permanent grassland": "Dauergrünland",
    "Photos": "Fotos",
    "Pine tree": "Nadelbaum",
    "Plan": "Plan",
    "Plans": "Pläne",
    "Plot name": "Feldname",
    "Plots": "Flachen",
    "Potato": "Kartoffel",
    "Potato limit": "Kartoffellimit",
    "Precipitation deficit": "Niederschlagsdefizit",
    "Preliminary": "Vorläufig",
    "Premium": "Premium",
    "Prescription map": "Applikationskarte",
    "Previous page": "Vorherige Seite",
    "Print field": "Feld drucken",
    "Pro": "Pro",
    "Profile": "Profil",
    "Province": "Kreis",
    "Public": "Öffentlich",
    "Public Plots": "Öffentliche Felder",
    "Pumpkin": "Kürbis",
    "RVO account valid until": "RVO-Konto gültig bis",
    "Radar": "Radar",
    "Radish": "Rettich",
    "Random": "Random",
    "Rapeseed": "Raps",
    "Read more": "Weiterlesen",
    "Reed": "Schilf",
    "Reet": "Schilf",
    "Refresh": "Aktualisieren",
    "Refresh RVO data": "RVO-Daten aktualisieren",
    "Refresh own data": "Eigene Daten aktualisieren",
    "Reg soil type": "Gronds. Mistgesetz",
    "Regulatory": "Regulatory",
    "Regulatory soil type": "Behördlicher Bodentyp",
    "Remove": "Löschen",
    "Report": "Datenblatt",
    "Reset all filters": "Alle Fitler zurücksetzen",
    "Review many years with a subscription": "Mit einem Abonnement können Sie viele Jahre zurückblicken",
    "Rice": "Reis",
    "Rose": "Rose",
    "Route planner and precise field information": "Routenplaner und genaue Felddaten",
    "Rye": "Roggen",
    "Rye-grass": "Roggengras",
    "Sand": "Sand",
    "Sandy Clay": "Sandiger Ton",
    "Sandy Clay-Loam": "Sandiger Ton-Lehm",
    "Sandy Loam": "Sandiger Lehm",
    "Satellite image date": "Datum Satellitenbild",
    "Search": "Suche",
    "Search fields by attribute throughout the country": "Grundstucke nach Merkmalen im ganzen Bundesland durchsuchen",
    "Search filter": "Suchfilter",
    "Search filter exports": "Suchfilter export",
    "Search for address, city, zip, GPS or XY": "Suche nach Adresse, Postleitzahl, GPS/XY",
    "See <a href=\"{{href}}\">{{description}}</a> for more information": "Weitere Informationen finden Sie unter <a href=\"{{href}}\">{{description}}</a>",
    "Select a list to view, add or remove the plots in it.": "Wählen Sie eine Liste aus, um die Felder darin anzuzeigen, hinzuzufügen oder zu entfernen.",
    "Selected list was deleted": "Ausgewählte Liste wurde gelöscht",
    "Selected plots": "Ausgewählte Bunder",
    "Selection": "Auswahl",
    "Send": "Senden",
    "Send a question or remark": "Frage oder Kommentar senden",
    "Sensitivity": "Empfindlichkeit",
    "Session logged out due to inactivity or remote logout": "Aufgrund von Inaktivität abgemeldet oder an anderer Stelle angemeldet",
    "Set address": "Adresse einstellen",
    "Settings": "Einstellungen",
    "Shadow": "Schatten",
    "Shadow map": "Schattenkarte",
    "Share": "Teilen",
    "Share {{subject}}": "Teil {{subject}}",
    "Shared list": "Geteilte Liste",
    "Short lease": "Kurzfristige Miete",
    "Show drainage lines": "Entwässerungslinien anzeigen",
    "Show less": "Zeige weniger",
    "Show shadows": "Schattenkarte",
    "Show soil potential": "Bodenpotential zeigen",
    "Show trenches": "Bewässerungsgräben anzeigen",
    "Silt": "Schluff",
    "Silty Clay-Loam": "Schluffiger Ton-Lehm",
    "Small-large": "Klein-Groß",
    "Socials": "Socials",
    "Soil Potential Map": "Bodenpotenzialkarte",
    "Soil type": "Bodentyp",
    "Some shadow": "Etwas Schatten",
    "Source": "Quelle",
    "Specialty filter": "Spezialfilter",
    "Spinach": "Spinat",
    "Start with precision farming, with insight into altitude maps and satellite images": "Start with precision farming, with insight into altitude maps and satellite images",
    "Stop editing": "Beenden Sie die Bearbeitung",
    "Strawberry": "Erdbeer",
    "Subscribe": "Abonnieren",
    "Subscribe for all filter options": "Abonnieren sie sich für alle Filteroptionen",
    "Subscribe for filtering on <i>{{attr}}</i>": "Abonnieren Sie den Filter für <i>{{attr}}</i>",
    "Subscribe to CropFields to view all satellite images": "Abonnieren, um alle Satellitenbilder auf der Karte zu sehen",
    "Subscribe to use all options": "Abonnieren, um alle Optionen anzuzeigen",
    "Subscribe to use this functionality": "Abonnieren, um diese Funktionalität zu nutzen",
    "Subscribe to view all available height data sources": "Abonnieren, um alle verfügbaren Jahre anzuzeigen",
    "Subscribe to view the exact area": "Abonnieren, um die genaue Oberfläche zu sehen",
    "Subscribe to view this data. CropFields is a website for farmers. It is used by many users for free. This is great, but we would like to make the site even better. Help us by <a href=\"{{url}}\">subscribing to CropFields</a>.": "Melden Sie sich an, um diese Daten anzuzeigen. Bauerngrund ist eine Website für Landwirte. Es wird von vielen Benutzern kostenlos verwendet. Das ist toll, aber wir möchten die Seite noch besser machen. Helfen Sie uns, indem Sie <a href=\"{{url}}\">Bauerngrund abonnieren</a>.",
    "SubscribeToSeeSharedData": "<a href=\"{{href}}\">Abonnieren</a> von B&G, um die geteilte Auswahl anzuzeigen. Wenn Sie bereits ein Abonnement haben, melden Sie sich an und aktivieren Sie die Feldauswahl-Tool-Option unter Einstellungen.",
    "Subscription": "Abonnement",
    "Subscriptions": "Abonnements",
    "Sugar beet": "Zuckerrübe",
    "Summer rye": "Sommerroggen",
    "Sunflower": "Sonnenblume",
    "Sustainable lease conditions": "Nachhaltige Mietkonditionen",
    "Switch to": "Wechseln zu",
    "Tel +31 88 3226600": "-",
    "Tell me more about the subscriptions": "Abonnementinformationen",
    "Terms": "Bedingungen",
    "TextAllImagesPro": "Abonnieren Sie Sich, um jedes verfügbare Bild auf der Karte anzuzeigen. Ohne Bauer&Grund Pro wird nur ein (relativ aktuelles) Demobild auf der Karte angezeigt. Mit einem Abonnement können alle 'Punkte' angeklickt werden und jedes Bild pro Plot kann auf der Karte angezeigt werden.",
    "TextBetterUse": "um Bauerngrund noch besser zu nutzen. Mit einem Abonnement können Sie Felder für spätere Ebenen speichern. High Level anzeigen und umfangreiche Suchfunktionen. Sie können auch Felder Drucken und Exportieren nach Shapefile und andere Formate.",
    "TextCookies": "Darüber hinaus verwendet Bauer&Grund Cookies. Wir verwenden Cookies, um Statistiken (anonymisiert anhand der IP-Adresse) zu führen und die Benutzererfahrung zu optimieren. Die Cookies werden nicht an Dritte weitergegeben und nach {{cookieDays}} Tagen automatisch gelöscht. Lesen Sie mehr dazu in unseren<a href=\"{{termsAndConditionsUrl}}\">Allgemeinen Geschäftsbedingungen</a> und der <a href=\"{{privacyStatementUrl}}\">Datenschutzerklärung</a>.",
    "TextDownloadSearchFilter": "Laden Sie die Felder von Ihrem Suchfilter herunter. Die Felder werden  gebündelt mit Attribute in einer Excel-Datei und einem Shapefile.",
    "TextFieldsInView": "So haben Sie die interessanten Grundstücke immer mit einem Klick im Blick",
    "TextLetUsKnow": "Lassen Sie uns hören, was Sie von Bauerngrund halten. Die Weiterentwicklung von Bauerngrund wird von den vielen Fragen der Benutzer angetrieben. Haben Sie eine Frage oder Kommentar? Wir würden gerne von Ihnen hören.",
    "TextLikeFields": "Hier ist eine Übersicht der Parzellen, denen Sie folgen. Um einem Bunder zu folgen Sie können eine auf der Karte auswählen und auf das Herz klicken",
    "TextNationalLayer": "Mit einem Bauer&Grund-Abonnement können Sie {{layer}} auf nationaler Ebene mit Attributen in einer Excel-Datei und einem Shapefile anzeigen.",
    "TextSettingsInfo": "Konfigurieren Sie hier Ihre Einstellungen. Als PRO Benutzer können Sie bestimmte Funktionen aktivieren und die Website optimal nutzen. Probieren Sie sie einzeln aus und lassen Sie uns wissen, was Sie davon halten",
    "TextTerms": "Bauerngrund visualisiert offene Datensätze. Die Daten stammen aus verschiedenen verfügbare Open Source, wie von verschiedenen Regierungen angeboten. Dacom kann nicht Garantien geben über die Qualität oder Richtigkeit der Daten. Wir visualisieren die Informationen nach besten Kräften. Für Genauigkeit und die Aktualität der Daten sollte kontaktiert werden mit den Quelleninhaber. Wir verweisen in unserer Bewerbung auf die Quelleninhaber unter dem",
    "TextViewedTooManyPlots": "Sie haben sich diese Woche bereits {{maxFields}} Felder kostenlos angesehen! Wir arbeiten hart daran, Bauer&Grund werbefrei und aktuell zu halten, einen guten Service zu bieten und wir aktualisieren und fügen relevante Datensätze ständig hinzu. Aus diesem Grund haben wir uns entschieden, professionellen Nutzern ein Pro-Abonnement für 13 € pro Monat anzubieten.",
    "TextViewedTooManyPlotsNoCookies": "Wir bemühen uns sehr, unsere unabhängige Plattform Bauer&Grund werbefrei und aktuell zu halten, einen guten Service zu bieten und relevantere Datensätze bereitzustellen. Bitte helfen Sie uns als professionellen Benutzer, ein Pro-Abonnement für 13 € pro Monat zu abonnieren.",
    "TextWithAProSubscription": "<p>Mit einem Pro-Abonnement</p><ul><li>Können Sie Felder schnell finden und dorthin navigieren</li><li>Kennen Sie die genaue bebaubare Fläche von Ackerflächen</li><li>Verstehen Sie die Fruchtfolgen pro Parzelle, um einen Anbauplan zu planen</li><li>Verwenden Sie Höhenkarten und Satellitenbilder für die Präzisionslandwirtschaft</li><li>Verwenden Sie die Katasterinformationen, um geeignetes Land zur Pacht oder zum Verkauf zu finden</li></ul>",
    "Thanks for you message": "Danke für deine Nachricht",
    "The farm": "Die Farm",
    "The list is empty.": "Die Liste ist leer.",
    "The public state of your farm has been changed": "Der öffentliche Status Ihrer Farm wurde geändert",
    "The public state of your list has been changed": "Der öffentliche Status Ihrer Liste wurde geändert",
    "This field could not be found!": "Dieses Feld konnte nicht gefunden werden!",
    "This field is located within the boundaries of nature collective": "Dieses Feld befindet sich innerhalb der Grenzen des Naturkollektivs",
    "This field is part of habitats": "Dieses Feld ist Teil der Lebensräume",
    "This field is part of the Nature Network of this country": "Dieses Grundstück ist Teil des Naturnetzwerks dieses Landes",
    "This field is used as <i>{{useTitle}}</i>": "Dieses Feld wird gebrauch als <i>{{useTitle}}</i>",
    "This is a pro feature": "Dies ist eine Pro-Funktion",
    "This plot is owned by state institute <i>{{name}}</i>": "Dieses Grundstück gehört dem staatlichen Institut <i>{{name}}</i>",
    "This session has expired or has been terminated remotely with a different login": "Diese Sitzung ist abgelaufen oder wurde beendet, weil sich dieses Konto auf zu vielen Geräten befindet.",
    "Title": "Titel",
    "Today": "Heute",
    "Tomato": "Tomate",
    "Total": "Summe",
    "Tree": "Baum",
    "Trenches": "Gräben",
    "Tulip": "Tulip",
    "Unknown": "Unbekannt",
    "Unselect plots": "Felder abwählen",
    "Upload": "Hochladen",
    "Uploading": "Hochladen",
    "Uploading this file will overwrite your current lease list": "Durch das Hochladen dieser Datei wird Ihre aktuelle Mietliste überschrieben",
    "Use title": "Mietart",
    "Vegetables": "Gemüse",
    "Video": "Video",
    "View all datasets": "Alle Datensätze anzeigen",
    "View cadastral data": "Katasterdaten anzeigen",
    "View crop rotations for creating a healthy cultivation plan": "Ansicht von Fruchtfolgen zur Erstellung eines gesunden Anbauplans",
    "View many historic crop rotation years": "Rückblick auf viele Jahre in der Fruchtfolge",
    "View {{subject}} on CropFields": "Sehe {{subject}} auf Bauerngrund",
    "WDVI images": "WDVI-Bilder",
    "Water board": "Wasserverband",
    "Water trap": "Wassertrappe",
    "Website": "Webseite",
    "With a crop recording subscription, you can work with multiple users on the same company & subscription": "Mit einem Erntegutregistrierungsabonnement können Sie es mit mehreren Personen verwenden auf dem gleiche Firma & Abonnementarbeit",
    "With a free account you can use many extra functionalities:": "Sie können mit ein kostenlos Konto viele zusätzliche Funktionen nutzen:",
    "With the free version of CropFields, you can view the basic data of a plot such as crop group, location and agricultural area.": "Mit der kostenlosen Version von Bauer&Grund können Sie die Grunddaten einer Parzelle wie Ernte, Standort und landwirtschaftliche Fläche anzeigen.",
    "Without a subscription, you can view data of {{maxFields}} crop fields per week for free.": "Ohne Abonnement können Sie kostenlos Daten von {{maxFields}} feldern pro Woche anzeigen.",
    "Would you like to view additional crop fields?": "Möchten Sie zusätzliche Felder anzeigen?",
    "XY coordinates": "XY-Koordinaten",
    "Yes, make my farm profile public": "Ja, mein Farmprofil öffentlich machen",
    "Yes, make one of my lists public": "Ja, mache eine meiner Listen öffentlich",
    "You can be logged in on up to two devices": "Sie können mit bis zu zwei Geräten bei demselben Konto angemeldet sein.",
    "Your contact information is public to other users.": "Ihre Kontaktinformationen sind für andere Benutzer öffentlich.",
    "Your one-month trial subscription has expired": "Ihr einmonatiges Testabonnement ist abgelaufen",
    "Your question or remark": "Ihre Frage oder Ihr Kommentar",
    "Zip code": "Postleitzahl",
    "Zipcode/city": "Postleitzahl / Stadt",
    "all images": "alle Bilder",
    "average": "durchschnitt",
    "avg": "gem",
    "by": "von",
    "crop rotation": "fruchtvolge",
    "difference": "Unterschied",
    "different attributes": "verschiedene Attribute",
    "different soil types": "verschiedene Bodentypen",
    "error": "Error",
    "example": "Beispiel",
    "fields": "Flurstücke",
    "filterTextAccount": "Erstellen Sie ein Konto oder melden Sie sich an, um alle Filteroptionen zu verwenden",
    "get a free trial subscription to CropFields": "holen Sie sich ein kostenloses Probeabonnement für Bauer&Grund",
    "heighest": "am höchsten",
    "l_cadastre_p1": "Kataster p1",
    "l_cadastre_p2": "Kataster p2",
    "l_info_bufferzones": "Ein Pufferstreifen ist ein Streifen Land, auf dem Sie keine Gülle, chemischen Pflanzenschutzmittel oder Biozide verwenden dürfen. Das schützt die Wasserqualität und fördert die Biodiversität. Ein Pufferstreifen befindet sich entlang eines Wasserlaufs, der neben landwirtschaftlichen Flächen liegt.",
    "l_info_ews": "Das Infektionsradar ermöglicht es Ihnen, drohende Risiken für Ihre Pflanzen durch Krankheiten zu erkennen.",
    "l_info_facts": "Informationen zu jedem Plot werden durch mehrere Datenquellen gesammelt",
    "l_info_growth": "Die aktuelle Vegetationskarte aus Satellitenbildern. So können Sie zum Beispiel verfolgen, wann die Aussaat und Ernte pro Feld erfolgt.",
    "l_info_height": "Digitale Höhenkarte. Ein Bauer&Grund-Abonnement ermöglicht mehrere Versionen.",
    "l_info_lease": "Bieten Sie dieses Feld zur Pacht an und möchten Sie erzählen, wie Sie nachhaltige Aktivitäten anregen? Weitere Informationen finden Sie unter <a href=\"/page/lease\">Miete</a>.",
    "l_info_nature": "Natura 2000 ist ein europäisches Netzwerk von Naturschutzgebieten. In Natura-2000-Gebieten werden bestimmte Tierarten und ihre natürlichen Lebensräume geschützt, um die biologische Vielfalt zu erhalten.<br />Siehe <a href=\"https://ec.europa.eu/environment/nature/natura2000/index_en.htm\">Europäischer Überblick</a>.",
    "l_info_property": "Katasterpakete, die vom örtlichen Kataster abgeholt werden. Diese Grundstücke beschreiben die Eigentumsgrenzen.",
    "l_info_soil": "Bodenklassifizierung basierend auf lokalen Karten. Jede Region definiert ihre eigene Bodenklassifizierungsstrategie.",
    "l_info_use_title": "Hier zeigen wir den Nutzungstitel, wie er von der Regierung in der Gemeinsame Agrarpolitik erhoben wurde. Diese Daten wurden NICHT von der Regierung überprüft, sie sind indikativ und es können keine Rechte daraus abgeleitet werden. Es besteht eine Diskrepanz zwischen staatlichen Grundstücken und den Katasterabschnitten die einen bestimmten Verwendungstitel haben.",
    "l_info_water": "Gräben und Wasserwege",
    "l_info_weather": "Niederschlagsdefizit",
    "legend": "Legende",
    "length": "Länge",
    "lowest": "niedrigste",
    "max": "max",
    "meter": "Meter",
    "min": "min",
    "more years": "mehr Jahre",
    "nr": "Nr.",
    "or": "oder",
    "province and municipality borders": "Kreise- und Gemeindegrenzen",
    "restrictedAccount": "Erstellen Sie ein kostenlos Konto oder logge dich ein",
    "settings_WDVI_images": "Ein Nachteil von NDVI ist, dass Satellitenbilder später in der Saison nicht mehr zwischen einer vollen und einer sehr vollen Ernte unterscheiden können. WDVI-Bilder leiden darunter weniger. Dieser Wachstumsindex berücksichtigt auch die Reflexion der nackten Das verbessert den Kontrast.",
    "settings_crop_predictor_feature_warning": "Diese Funktion ist für eine Weile deaktiviert. Lassen Sie es uns wissen, wenn Sie der Meinung sind, dass dies eine relevante Option ist, und sie wird in der Prioritätenliste nach oben verschoben.",
    "settings_crop_predictor_hanze": "In Zusammenarbeit mit der Hanze University Groningen hat Dacom ein Modell entwickelt, mit dem zukünftige Ernten vorhergesagt werden können, den Crop Predictor.",
    "settings_crop_predictor_info_1": "Schalten Sie den Schalter ein, damit Sie für jede Anbaufläche in Bauer&Grund sehen können, welche Kulturen wir für jede Fläche im Jahr 2021 basierend auf früheren Fruchtfolgen und aktuellen Satellitenbildern vorhersagen.",
    "settings_crop_predictor_info_2": "Mithilfe von Deep-Learning-KI und Algorithmen deckt das Modell zugrunde liegende Muster im Datensatz auf. Die Eingabe des Modells basiert zunächst auf der Fruchtfolge aller niederländischen Parzellen, wie sie in der Basis Registratie Percelen für den Zeitraum von 2005 bis 2020 registriert sind. Die Prognose für 2021 wird sich im Laufe des Jahres weiter verbessern, sobald neue Satellitenbilder zur Verfügung stehen. Kommen Sie. Das Modell betrachtet dann beispielsweise die NDVI-Wachstumskurve eines Plots und vergleicht sie mit den Vorjahren.",
    "settings_crop_predictor_info_3": "Nach dem Einschalten des Schalters zeigen wir auf Bauer&Grund drei Ernten an, die am wahrscheinlichsten dem Modell für jede Parzelle entsprechen. Ein hoher Prozentsatz für eine Kultur bedeutet, dass das Modell zuvor ein solches Muster in den niederländischen Parzellen beobachtet hat und dass das Modell davon ausgeht, dass diese Ernte im Jahr 2021 am wahrscheinlichsten sein wird. ",
    "settings_ditches": "Gräben und Wasserwege",
    "settings_drainage_map": "Die Entwässerungskarte ist basiert auf der aktuellsten Höhenkarte. Sie können sehen, wo das Wasser fließt. Mit dieser Karte können Sie beispielsweise die Erosion auf Ihrem Grundstück analysieren oder bei Überschwemmungen Gräben ausheben.",
    "settings_ews_feature": "Mit dem Infektionsradar von Dacom erhalten Sie zeitnah Informationen über das Infektionsrisiko von etwa 20 Pilzkrankheiten. Damit decken die Dacom-Modelle die wichtigsten Kulturpflanzen in den Niederlanden ab. Anfangs werden wir klein anfangen, aber im Laufe der Saison werden immer mehr Funktionalitäten hinzugefügt. Behalten Sie den Fortschritt im Auge, indem Sie regelmäßig auf EWS in der linken Menüleiste von Bauer&Grund klicken.",
    "settings_field_selection_tool": "Als Landwirt werden Sie oft gebeten, Ihre Felder zu teilen. Mit dem neuen Feldauswahl-Tool können Sie jetzt auch mehrere Felder gleichzeitig freigeben. Klicken Sie oben rechts auf das Feldsymbol und dann können Sie auf mehrere Felder klicken, um eine praktische Liste zusammenzustellen. Dann senden Sie es per WhatsApp, Twitter, Facebook oder Ihrer E-Mail. Der Empfänger sieht in Bauer&Grund genau dieselbe Liste, die Sie gesendet haben. Wenn Sie möchten, können Sie auch eine Shape-Datei mit einer praktischen Excel-Datei senden.",
    "settings_growth_course": "Mit unserer neuen Timeline Wachstumskurs sehen Sie auf einen Blick die räumliche Variation eines Plots über die Zeit. So können Sie noch schneller anomale Stellen in einem Feld entdecken oder das richtige Satellitenbild für eine Applikationskarte auswählen.",
    "settings_lease": "Geben Sie selbst Informationen über die Pacht eines Grundstücks weiter.",
    "settings_precip_deficit": "Das Niederschlagsdefizit visualisiert, wie trocken es an verschiedenen Orten im Land ist..",
    "settings_shadow_map": "Die Schattenkarte zeigt auf jeder Feld an, wie viel Schatten während der Vegetationsperiode auf die Fläche fällt. Mit diesen Informationen können Sie beispielsweise den Reihenabstand in den schattierten Bereichen anpassen oder nachträglich analysieren, warum der Ertrag auf bestimmten Teilen des Feldes hinter dem Rest zurückbleibt.",
    "settings_soil_potency_map": "Mit der Bodenpotenzkarte zeigen wir, welche Teile einer Parzelle strukturell besser oder weniger leistungsfähig sind. Dazu überlagern wir Satellitenbilder der letzten 5 Jahre. Auf diese Weise entdecken wir Muster und beseitigen saisonale Einflüsse. Mit dieser Karte können Sie beispielsweise einen Düngeplan erstellen oder sehen, ob Maßnahmen erforderlich sind, die die Bodenstruktur verbessern können.",
    "show trenches, ditch sides": "Bewässerungsgräben/Grabenränder anzeigen",
    "subscribe here to CropFields": "Hier ein Bauerngrund-Abonnement abschließen",
    "the CropFields subscription": "das Bauerngrund abonnement",
    "the nature collective": "das Naturkollektiv",
    "this blog": "dieser Blog",
    "this farm": "diese Farm",
    "this field": "diese Parzelle",
    "this field selection": "diese Feldauswahl",
    "type": "Typ",
    "waterways": "Wasserstraßen",
    "{{days}} days remaining": "Noch {{days}} Tage",
    "{{fields}} Fields": "{{fields}} Felder",
    "{{hitsCount}} results": "{{hitsCount}} Ergebnisse",
    "{{percentage}}% chance on {{subject}}": "{{percentage}}% Chance auf {{subject}}"
}},
"en": {translation: {
    "<2ha, 2-4ha, 4-10ha and 10+ ha": "<2ha, 2-4ha, 4-10ha and 10+ ha",
    "<a href=\"/authorize\">Login</a> to CropFields to view the cadastral field mapping": "<a href=\"/authorize\">Login</a> to CropFields to view the cadastral field mapping",
    "<a href=\"{{href}}\">Subscribe</a> to CropFields to view the cadastral field mapping": "<a href=\"{{href}}\">Subscribe</a> to CropFields to view the cadastral field mapping",
    "<a href=\"{{href}}\">Subscribe</a> to CropFields to view the water information.": "<a href=\"{{href}}\">Subscribe</a> to CropFields to view the water information.",
    "About CropFields": "About CropFields",
    "About the farm": "About the farm",
    "Account": "Account",
    "AccountFeatures": "<li> Looking back many years in crop rotation </li> \n<li> Route planner and exact field data </li> \n<li> See cadastral data </li>  \n<li> View satellite data details </li> \n<li> Elevation maps of different times </li> \n<li> Report crop field (PDF) </li> \n<li> Export field data (Shapefile) </li> \n<li> Filter fields by all characteristics </li> \n<li> Take advantage of all available beta features </li>",
    "Active sessions": "Active sessions",
    "Add to list": "Add to list",
    "Add your own plots": "Add your own plots",
    "Address": "Address",
    "Agricultural area": "Agricultural area",
    "All": "All",
    "Altitude": "Elevation",
    "Always know the exact cultivable area of fields for planning and invoicing": "Always know the exact cultivable area of fields for planning and invoicing",
    "Analysis": "Analyis",
    "Apple": "Apple",
    "Arable land": "Arable land",
    "Are you sure?": "Are you sure?",
    "Area": "Area",
    "Asparagus": "Asparagus",
    "Attention! This export only contains a part of the search result": "Attention! This export only contains a part of the search result",
    "Attributes": "Attributes",
    "Author": "Author",
    "BETA": "BETA",
    "Back": "Back",
    "Back to CropFields": "Back to CropFields",
    "Bans": "Bans",
    "Barley": "Barley",
    "Bean": "Bean",
    "Beet": "Beet",
    "Berry": "Berry",
    "Beta": "Beta",
    "Birds and Habitats Directive": "Birds and Habitats Directive",
    "Birds directive": "Birds directive",
    "Blog": "Blog",
    "Blog unavailable": "Blog unavailable",
    "Broccoli": "Broccoli",
    "Buffer zone": "Buffer zone",
    "Building": "Building",
    "Bulb": "Bulb",
    "By using this website you agree to the terms above": "By using this website you agree to the terms above",
    "Cabbage": "Cabbage",
    "Cadastral field": "Cadastral field",
    "Cadastre": "Cadastre",
    "Cadastre data unknown": "Cadastre data unknown",
    "Cancel": "Cancel",
    "Capsicum": "Capsicum",
    "Carrot": "Carrot",
    "Cauliflower": "Cauliflower",
    "Cherry": "Cherry",
    "Chicory": "Chicory",
    "Chicory root": "Chicory root",
    "Choose file": "Choose file",
    "City": "City",
    "Clay": "Clay",
    "Clay-Loam": "Clay-Loam",
    "Clear filters": "Clear filters",
    "Click here": "Click here",
    "Click on the map to expand the measurement": "Click on the map to expand the measurement",
    "Click on the map to expand the measurement, or click on the last point to finish the measurement": "Click on the map to expand the measurement, or click on the last point to finish the measurement",
    "Click to start measurement": "Click to start measurement",
    "Close": "Close",
    "Clover": "Clover",
    "Company": "Company",
    "Configure settings": "Configure settings",
    "Connect to RVO": "Connect to RVO",
    "Contact": "Contact",
    "Continue without cookies": "Continue with only required cookies",
    "Coordinates": "Coordinates",
    "Corn": "Corn",
    "Could not refresh": "Could not refresh",
    "Country choice": "Country choice",
    "Create a free account or login to use this functionality": "Create a free account or login to use this functionality",
    "Create an account": "Create an account",
    "Create and share your own field lists": "Create and share your own field lists",
    "Create field report": "Create field report",
    "Create prescription maps with Cloudfarm": "Create isobus task maps / prescription maps with Cloudfarm",
    "Crop": "Crop",
    "Crop Predictor": "Crop Predictor",
    "Crop per year": "Crop per year",
    "Crop rotation": "Crop rotation",
    "CropFields": "CropFields",
    "CropFields blog": "CropFields blog",
    "CropFields crop prediction for {{year}}": "CropFields crop prediction for {{year}}",
    "CropFields link": "CropFields link",
    "Cucumber": "Cucumber",
    "Custom lists": "Custom lists",
    "Dacom is building a number of new features for CropFields. Anyone with a PRO or higher can try out these features for themselves.": "Dacom is building a number of new features for CropFields. Anyone with a PRO or higher can try out these features for themselves.",
    "Deficit": "Deficit",
    "Delete RVO account": "Delete RVO account",
    "Discover": "Discover",
    "Ditches": "Ditches",
    "Ditches on map": "Ditches on map",
    "Do you want more information about the Pro subscription? Then click <a href=\"{{url}}\">here</a>.": "Do you want more information about the Pro subscription? Then click <a href=\"{{url}}\">here</a>.",
    "Do you want to use CropFields with multiple users in your company? Please contact us via <a href=\"mailto:info@dacom.nl\">info@dacom.nl</a>.": "Do you want to use CropFields with multiple users in your company? Please contact us via <a href=\"mailto:info@dacom.nl\">info@dacom.nl</a>.",
    "Download Geotiff": "Download Geotiff",
    "Download Shapefile": "Download Shapefile",
    "Drainage map": "Drainage map",
    "Edit": "Edit",
    "Edit list": "Edit list",
    "Edit my profile": "Edit my profile",
    "Edit name": "Edit name",
    "Edit plots": "Edit fields",
    "Edit your profile and choose your settings.": "Edit your profile and choose your settings.",
    "Egg plant": "Eggplant",
    "Email": "Email",
    "Empty": "Empty",
    "Enter a valid email address": "Enter a valid email address",
    "Enter company name": "Enter company name",
    "Enter your name": "Enter your name",
    "Erosion": "Erosion",
    "Erosion sensitivity": "Erosion sensitivity",
    "Error occurred": "Error occurred",
    "Export capacity after this export": "Export capacity after this export",
    "Export field data to shape file": "Export field data to shape file",
    "Export {{count}} fields": "Export {{count}} fields",
    "Exported field count": "Exported field count",
    "Exported fields": "Exported fields",
    "Facts": "Facts",
    "Failed to change account": "Failed to change account",
    "Failed to create favorite": "Failed to create favorite",
    "Failed to delete selected list": "Failed to delete selected list",
    "Failed to edit name": "Failed to edit name",
    "Failed to fetch farm plots": "Failed to fetch farm plots",
    "Failed to fetch farms": "Failed to fetch farms",
    "Failed to make farm public": "Failed to make farm public",
    "Failed to make list public": "Failed to make list public",
    "Failed to send mail, sorry": "Failed to send mail, sorry",
    "Failed to show shared field": "Failed to show shared field",
    "Failed to show shared list": "Failed to show shared list",
    "Farm": "Farm",
    "Farm overview": "Farm overview",
    "Farm profile": "Farm profile",
    "Favorite": "Favorite",
    "Feedback": "Feedback",
    "Fetch data": "Fetch data",
    "Fetching data": "Fetching data",
    "Field": "Field",
    "Field export capacity": "Field export capacity",
    "Field export quota": "Field export quota",
    "Field report": "Field report",
    "Field selection": "Field selection",
    "Field selection tool": "Field selection tool",
    "Field size": "Field size",
    "Fields": "Fields",
    "Fields in this export": "Fields in this export",
    "Filter": "Filter",
    "Find and quickly navigate to fields": "Find and quickly navigate to fields",
    "Find suitable land for renting or purchasing": "Find suitable land for renting or purchasing",
    "Flower": "Flower",
    "Focus area nitrate": "Focus area nitrate",
    "Follow on social media": "Follow on social media",
    "Follow satellite data": "Follow satellite data",
    "For more information, see": "For more information, see",
    "For only €16/month you will get all the functionalities of CropFields Pro again": "For only €16/month you will get all the functionalities of CropFields Pro again",
    "Forbidden": "Forbidden",
    "Forbidden irrigation": "Irrigation ban",
    "Forest": "Forest",
    "Free Pro": "Free Pro",
    "Free Pro expired": "Free Pro expired",
    "Free trial": "Free trial",
    "French bean": "Green bean",
    "Fruit": "Fruit",
    "GPS coordinates": "GPS coordinates",
    "Government property": "Government property",
    "Grain": "Grain",
    "Grape": "Grape",
    "Grass": "Grass",
    "Grass land": "Grass land",
    "Ground lease": "Ground lease",
    "Ground water": "Ground water",
    "Ground water protection": "Ground water protection",
    "Ground water protection area": "Ground water protection area",
    "Growth": "Growth",
    "Growth course": "Growth course",
    "Growth potential": "Growth potential",
    "Growth with satellite images": "Growth with satellite images",
    "Habitat directive": "Habitat directive",
    "Head cabbage": "Cabbage",
    "Height maps measured on different times": "Height maps measured on different times",
    "Hemp": "Hemp",
    "Herbs": "Herbs",
    "I accept": "I accept",
    "I want to share {{subject}}": "I want to share {{subject}}",
    "If you have any questions or comments, email us at": "If you have any questions or comments, email us at",
    "Infection chance": "Infection rate",
    "Infection chance potato fungus": "Infection chance potato fungus",
    "Infection radar": "Infection radar",
    "Initialising": "Initialising",
    "Institute": "State property",
    "Irrigation limit": "Irrigation limit",
    "It concerns the first {{count}} fields measured from the center of the map (GPS location {{gps}})": "It concerns the first {{count}} fields measured from the center of the map (GPS location {{gps}})",
    "Large-small": "Large-small",
    "Layers": "Layers",
    "Lease": "Lease",
    "Lease list": "Lease list",
    "Lease status unknown": "Lease status unknown",
    "Leek": "Leek",
    "Leeks": "Leeks",
    "Less": "Less",
    "Lettuce": "Lettuce",
    "Liked fields": "Liked fields",
    "Limited": "Limited",
    "List from {{name}}": "List from {{name}}",
    "List has been uploaded": "List has been uploaded",
    "Lists": "Lists",
    "Lists overview": "Lists overview",
    "Little shadow": "Little shadow",
    "Live preview": "Live preview",
    "Loading": "Loading",
    "Loading blog": "Loading blog",
    "Loam": "Loam",
    "Loamy Sand": "Loamy Sand",
    "Location": "Location",
    "Location not found": "Location not found",
    "Log out": "Log out",
    "Logged in as": "Logged in as",
    "Login": "Login",
    "Login or create an account": "Login or create an account",
    "Login to like farms and plots": "Login to like farms and plots",
    "Long lease": "Long lease",
    "Mail service@dacom.nl": "Mail service@dacom.nl",
    "Make farm profile public": "Make farm profile public",
    "Make one of my lists public": "Make one of my lists public",
    "Make selection smaller": "Make selection smaller",
    "Make your farm and fields visible for all CropFields users.": "Make your farm and fields visible for all CropFields users.",
    "Manage public profile": "Manage profile public/private",
    "Manage sessions": "Manage sessions",
    "Map": "Map",
    "Max annual fields export": "Max annual fields export",
    "Max fields in single export": "Max fields in single export",
    "Maximum reached, upgrade to Premium": "Maximum reached, upgrade to Premium",
    "Measure distance": "Measure distance",
    "Message": "Message",
    "Millet": "Millet",
    "More": "More",
    "More information": "More information",
    "More information about our subscriptions": "More information about our subscriptions",
    "More news": "More news",
    "More years": "More years",
    "Much shadow": "Much shadow",
    "Municipality": "Municipality",
    "My lists": "My lists",
    "Name": "Name",
    "Natura 2000": "Natura 2000",
    "Nature": "Nature",
    "Nature conservation": "Nature conservation",
    "New list": "New list",
    "Next page": "Next page",
    "Nitrate": "Nitrate",
    "Nitrate area": "Nitrate area",
    "No CropFields subscription yet": "No CropFields subscription yet",
    "No data": "No data",
    "No data available, please try again later": "No data available. Please try again later.",
    "No information found for this field": "No information found for this field",
    "No nature data known": "No nature data known",
    "No potatoes for {{year}} years": "No potatoes for {{year}} years",
    "No results": "No results",
    "No shadow": "No shadow",
    "No soil data available": "No soil data available",
    "Not within Natura 2000": "Not within Natura 2000",
    "Number in group": "Number in group",
    "Nut": "Nut",
    "Oats": "Oats",
    "Onion": "Onion",
    "Or use our contact form under": "Or use our contact form under",
    "Order Pro": "Order Pro",
    "Order a subscription": "Order a subscription",
    "Organic": "Organic",
    "Orthophoto": "Orthophoto",
    "Other attributes": "Other attributes",
    "Other lease": "Other lease",
    "Overlap": "Overlap",
    "Owned": "Owned",
    "Ownership": "Property",
    "PRO subscribers can configure additional features here": "PRO subscribers can configure additional features here",
    "Page not found": "Page not found",
    "Pasture": "Pasture",
    "Pea": "Pea",
    "Pear": "Pear",
    "Percentage": "Percentage",
    "Period": "Period",
    "Permanent grassland": "Permanent grassland",
    "Photos": "Photos",
    "Pine tree": "Pine tree",
    "Plan": "Plan",
    "Plans": "Plans",
    "Plot name": "Plot name",
    "Plots": "Plots",
    "Potato": "Potato",
    "Potato limit": "Potato limit",
    "Precipitation deficit": "Precipitation deficit",
    "Preliminary": "Preliminary",
    "Premium": "Premium",
    "Prescription map": "Prescription map",
    "Previous page": "Previous page",
    "Print field": "Print plot",
    "Pro": "Pro",
    "Profile": "Profile",
    "Province": "Province",
    "Public": "Public",
    "Public Plots": "Public Plots",
    "Pumpkin": "Pumpkin",
    "RVO account valid until": "RVO account valid until",
    "Radar": "Radar",
    "Radish": "Radish",
    "Random": "Random",
    "Rapeseed": "Rapeseed",
    "Read more": "Read more",
    "Reed": "Reed",
    "Reet": "Reet",
    "Refresh": "Refresh",
    "Refresh RVO data": "Refresh own data",
    "Refresh own data": "Refresh own data",
    "Reg soil type": "Reg soil type",
    "Regulatory": "Regulatory",
    "Regulatory soil type": "Official soil type",
    "Remove": "Remove",
    "Report": "Report",
    "Reset all filters": "Reset all filters",
    "Review many years with a subscription": "Review many years with a subscription",
    "Rice": "Rice",
    "Rose": "Rose",
    "Route planner and precise field information": "Route planner and precise field information",
    "Rye": "Ryw",
    "Rye-grass": "Rye-grass",
    "Sand": "Sand",
    "Sandy Clay": "Sandy Clay",
    "Sandy Clay-Loam": "Sandy Clay-Loam",
    "Sandy Loam": "Sandy Loam",
    "Satellite image date": "Satellite image date",
    "Search": "Search",
    "Search fields by attribute throughout the country": "Search fields by attribute throughout the country",
    "Search filter": "Search filter",
    "Search filter exports": "Search filter exports",
    "Search for address, city, zip, GPS or XY": "Search for address, city, zip, GPS or XY",
    "See <a href=\"{{href}}\">{{description}}</a> for more information": "See <a href=\"{{href}}\">{{description}}</a> for more information",
    "Select a list to view, add or remove the plots in it.": "Select a list to view, add or remove the plots in it.",
    "Selected list was deleted": "Selected list was deleted",
    "Selected plots": "Selected plots",
    "Selection": "Selection",
    "Send": "Send",
    "Send a question or remark": "Send a question or remark",
    "Sensitivity": "Sensitivity",
    "Session logged out due to inactivity or remote logout": "Session logged out due to inactivity or remote logout",
    "Set address": "Set address",
    "Settings": "Settings",
    "Shadow": "Shadow",
    "Shadow map": "Shadow map",
    "Share": "Share",
    "Share {{subject}}": "Share {{subject}}",
    "Shared list": "Shared list",
    "Short lease": "Short lease",
    "Show drainage lines": "Show drainage lines",
    "Show less": "Show less",
    "Show shadows": "Show shadows",
    "Show soil potential": "Show soil potential",
    "Show trenches": "Show embankments",
    "Silt": "Silt",
    "Silty Clay-Loam": "Silty Clay-Loam",
    "Small-large": "Small-large",
    "Socials": "Socials",
    "Soil Potential Map": "Soil Potential Map",
    "Soil type": "Soil type",
    "Some shadow": "Some shadow",
    "Source": "Source",
    "Specialty filter": "Specialty filter",
    "Spinach": "Spinach",
    "Start with precision farming, with insight into altitude maps and satellite images": "Start with precision farming, with insight into altitude maps and satellite images",
    "Stop editing": "Stop editing",
    "Strawberry": "Strawberry",
    "Subscribe": "Subscribe",
    "Subscribe for all filter options": "Subscribe for all filter options",
    "Subscribe for filtering on <i>{{attr}}</i>": "Subscribe for filtering on <i>{{attr}}</i>",
    "Subscribe to CropFields to view all satellite images": "Subscribe to CropFields to view all satellite images",
    "Subscribe to use all options": "Subscribe to use all options",
    "Subscribe to use this functionality": "Subscribe to use this functionality",
    "Subscribe to view all available height data sources": "Subscribe to view all available height data sources",
    "Subscribe to view the exact area": "Subscribe to view the exact area",
    "Subscribe to view this data. CropFields is a website for farmers. It is used by many users for free. This is great, but we would like to make the site even better. Help us by <a href=\"{{url}}\">subscribing to CropFields</a>.": "Subscribe to view this data. CropFields is a website for farmers. It is used by many users for free. This is great, but we would like to make the site even better. Help us by <a href=\"{{url}}\">subscribing to CropFields</a>.",
    "SubscribeToSeeSharedData": "<a href=\"{{href}}\">Subscribe</a> to CropFields to view the shared selection. If you already have a subscription, login and turn on the field selection tool option under Settings.",
    "Subscription": "Subscription",
    "Subscriptions": "Subscriptions",
    "Sugar beet": "Sugar beet",
    "Summer rye": "Summer rye",
    "Sunflower": "Sunflower",
    "Sustainable lease conditions": "Sustainable lease conditions",
    "Switch to": "Switch to",
    "Tel +31 88 3226600": "Tel: +31 88 3226600",
    "Tell me more about the subscriptions": "Tell me more about the subscriptions",
    "Terms": "Terms",
    "TextAllImagesPro": "Subscribe to view every available image on the map. Without CropFields Pro, only one (fairly recent) demo image is shown on the map. With a subscription, all 'dots' are clickable and each image per plot can be viewed on the map.",
    "TextBetterUse": "to maximise your CropFields user experience. With a subscription, you can favorite/save plots for later reference, view layers at national level and use advanced filter and search. Additionally, you can print plots and create data exports to shapefile and other data format.",
    "TextCookies": "In addition, CropFields uses cookies. We use cookies to keep statistics (anonymised based on IP address) and to optimise the user experience. The cookies are not shared with third parties and after {{cookieDays}} days the cookies are automatically deleted. Read more about this in our <a href=\"{{termsAndConditionsUrl}}\">terms and conditions</a> and <a href=\"{{privacyStatementUrl}}\">privacy statement</a>",
    "TextDownloadSearchFilter": "Download the parcels from your search filter. The plots are bundled with attributes in an Excel file and a Shapefile.",
    "TextFieldsInView": "This way, your interesting fields can be seen in a single click",
    "TextLetUsKnow": "Please let us know what you think of CropFields. The application development is guided by the feedback of our many users. For questions or remarks, please contact us.",
    "TextLikeFields": "To follow a field, select it on the map and presh the like/heart icon",
    "TextNationalLayer": "A CropFields subscription enables you to view {{layer}} on a national level with attributes in an Excel file and a Shapefile.",
    "TextSettingsInfo": "Configure your settings here. As a paid user, you can enable specific features and get the most out of the site. For suggestions, please contact",
    "TextTerms": "CropFields visualises open data sets. The displayed data has been gathered from many different open data sources (like governments). Although we do our best, Dacom can not guarantee the accuracy and/or actuality of every piece of information show on the website. Wij visualise and update the available data to the best of our knowledge. For the actual data and the precise interpretation, please contact the source holder that is responsible for updating and managing the data. Where possible, throughout the application we point to the website of the source holder with a ",
    "TextViewedTooManyPlots": "You've already viewed {{maxFields}} crop fields for free this week! We work hard to keep CropFields ad-free and up-to-date, to provide good service and we keep updating and adding relevant datasets. As a result, we have chosen to provide professional users with a Pro subscription for €16 per month.",
    "TextViewedTooManyPlotsNoCookies": "We spend a lot of time to keep our independent platform CropFields ad-free and up-to-date, to provide good service and to provide more relevant datasets. Please help us, as a professional user, in subscribing to a Pro subscription for €16 per month.",
    "TextWithAProSubscription": "<p>With a Pro subscription, you can</p><ul><li>Quickly find fields and navigate there</li><li>Know the exact cultivable surface of crop fields</li><li>Understand crop rotations per plot for planning a cropping scheme</li><li>Use elevation maps and satellite imagery for precision farming</li><li>Use the cadastral information to find suitable land for lease or for sale</li></ul>",
    "Thanks for you message": "Thanks for you message",
    "The farm": "The farm",
    "The list is empty.": "The list is empty.",
    "The public state of your farm has been changed": "The public state of your farm has been changed",
    "The public state of your list has been changed": "The public state of your list has been changed",
    "This field could not be found!": "This field could not be found!",
    "This field is located within the boundaries of nature collective": "This field is located within the boundaries of nature collective",
    "This field is part of habitats": "This field is part of habitats",
    "This field is part of the Nature Network of this country": "This field is part of the Nature Network of this country",
    "This field is used as <i>{{useTitle}}</i>": "This field is used as <i>{{useTitle}}</i>",
    "This is a pro feature": "This is a pro feature",
    "This plot is owned by state institute <i>{{name}}</i>": "This plot is owned by state institute <i>{{name}}</i>",
    "This session has expired or has been terminated remotely with a different login": "This session has expired or has been terminated remotely with a different login",
    "Title": "Title",
    "Today": "Today",
    "Tomato": "Tomato",
    "Total": "Total",
    "Tree": "Tree",
    "Trenches": "Embankments",
    "Tulip": "Tulip",
    "Unknown": "Unknown",
    "Unselect plots": "Unselect plots",
    "Upload": "Upload",
    "Uploading": "Uploading",
    "Uploading this file will overwrite your current lease list": "Uploading this file will overwrite your current lease list",
    "Use title": "Use title",
    "Vegetables": "Vegetables",
    "Video": "Video",
    "View all datasets": "View all datasets",
    "View cadastral data": "View cadastral data",
    "View crop rotations for creating a healthy cultivation plan": "View crop rotations for creating a healthy cultivation plan",
    "View many historic crop rotation years": "View many historic crop rotation years",
    "View {{subject}} on CropFields": "View {{subject}} on CropFields",
    "WDVI images": "WDVI images",
    "Water board": "Water board",
    "Water trap": "Water trap",
    "Website": "Website",
    "With a crop recording subscription, you can work with multiple users on the same company & subscription": "With a crop recording subscription, you can work with multiple users on the same company & subscription",
    "With a free account you can use many extra functionalities:": "With a free account you can use many extra functionalities:",
    "With the free version of CropFields, you can view the basic data of a plot such as crop group, location and agricultural area.": "With the free version of CropFields, you can view the basic data of a plot such as crop group, location and agricultural area.",
    "Without a subscription, you can view data of {{maxFields}} crop fields per week for free.": "Without a subscription, you can view data of {{maxFields}} crop fields per week for free.",
    "Would you like to view additional crop fields?": "Would you like to view additional crop fields?",
    "XY coordinates": "XY coordinates",
    "Yes, make my farm profile public": "Yes, make my farm profile public",
    "Yes, make one of my lists public": "Yes, make one of my lists public",
    "You can be logged in on up to two devices": "You can be logged in on up to two devices",
    "Your contact information is public to other users.": "Your contact information is public to other users.",
    "Your one-month trial subscription has expired": "Your one-month trial subscription has expired",
    "Your question or remark": "Your question or remark",
    "Zip code": "Zip code",
    "Zipcode/city": "Zipcode/city",
    "all images": "all images",
    "average": "average",
    "avg": "avg",
    "by": "by",
    "crop rotation": "crop rotation",
    "difference": "difference",
    "different attributes": "different attributes",
    "different soil types": "different soil types",
    "error": "error",
    "example": "example",
    "fields": "fields",
    "filterTextAccount": "Create an account or log in to use all of the filtering options",
    "get a free trial subscription to CropFields": "get a free trial subscription to CropFields",
    "heighest": "heighest",
    "l_cadastre_p1": "Cadastre ID 1",
    "l_cadastre_p2": "Cadastre ID 2",
    "l_info_bufferzones": "A buffer strip is a strip of land on which you are not allowed to use manure, chemical plant protection products or biocides. This protects the water quality and stimulates biodiversity. A buffer strip is located along a watercourse that lies alongside agricultural land.",
    "l_info_ews": "The Infection radr enables you to spot imminent risks to your crops due to disease.",
    "l_info_facts": "Farmers must submit an annual statement of all their plots to the government. With this open data you can view the crop rotation.",
    "l_info_growth": "The current vegetation map obtained from satellite images. For example, you can track when sowing and harvesting is done per field.",
    "l_info_height": "Digital elevation map. A CropFields subscription enables multiple versions.",
    "l_info_lease": "Are you offering this field for lease and do you want to tell how you stimulate sustainable activities? Check out <a href=\"/page/lease\">lease</a> for more information.",
    "l_info_nature": "Natura 2000 is a European network of protected natural areas. In Natura 2000 areas, certain animal species and their natural habitats are protected in order to preserve biodiversity.<br />See the <a href=\"https://ec.europa.eu/environment/nature/natura2000/index_en.htm\">European overview</a>.",
    "l_info_property": "Cadastral parcels collected from the local cadastre. These plots delineate ownership boundaries.",
    "l_info_soil": "Soil classification based on local maps. Every region defines their own soil classification strategy.",
    "l_info_use_title": "Here we show the usage title as the government has collected it in the CAP declaration. This data has NOT been checked by the government, it is indicative and no rights can be derived from it. There is a mismatch between government plots and the cadastral sections that have a specific usage title.",
    "l_info_water": "Ditches and waterways",
    "l_info_weather": "Precipitation deficit",
    "legend": "legend",
    "length": "length",
    "lowest": "lowest",
    "max": "max",
    "meter": "meter",
    "min": "min",
    "more years": "more years",
    "nr": "no",
    "or": "or",
    "province and municipality borders": "province and municipality borders",
    "restrictedAccount": "Create a free account or log in",
    "settings_WDVI_images": "A disadvantage of NDVI is that later in the season satellite images can no longer tell the difference between a full and a very full crop. WDVI images suffer less from this. This growth index also looks at the reflection of the bare soil. This improves the contrast.",
    "settings_crop_predictor_feature_warning": "This feature is disabled for a while. Let us know if you think this is a relevant option and it will move up the priority list.",
    "settings_crop_predictor_hanze": "In collaboration with Hanze University Groningen, Dacom has developed a model with which future crops can be predicted, the Crop Predictor.",
    "settings_crop_predictor_info_1": "Turn on the toggle so that for each crop plot in CropFields you can see which crops we predict for each plot in 2021 based on previous crop rotations and current satellite images.",
    "settings_crop_predictor_info_2": "Using deep learning AI and algorithms, the model uncovers underlying patterns in the dataset. The input of the model is initially based on the crop rotation of all Dutch parcels as registered in the Basis Registratie Percelen for the period from 2005 to 2020. The forecast for 2021 will continue to improve during the year, as new satellite images become available. come. The model then looks, for example, at the NDVI growth curve of a plot and compares it with previous years.",
    "settings_crop_predictor_info_3": "After turning on the toggle, on CropFields we display three crops that are most likely according to the model for each plot. A high percentage for a crop means that the model has previously observed such a pattern in the Dutch plots and that the model assumes that this crop will be most likely in 2021.",
    "settings_ditches": "In the Netherlands we have a lot of ditches (330,000 km). In CropFields you can now see the ditches that are immediately around a plot. You can also use the ditches as a background layer. At each plot you can find the length of the ditches. This is useful for farmers and contractors for, for example, the invoicing of ditch maintenance. You can also see where the entrance of the parcel islocated. The border of the ditch is also where the spray- and cultivation-free zone of a plot begins. The location of the dams indicates exactly how you can go through the land between the ditches.",
    "settings_drainage_map": "The drainage map is based on the most current elevation map. On the map you can see where the water is flowing. You can use this map, for example, to analyse erosion on your plot or to dig trenches if you have a flooding.",
    "settings_ews_feature": "With Dacom's Infection radar you receive timely information about the infection risk of about 20 fungal diseases. The Dacom models thus cover the most important cultivated crops in the Netherlands. Initially we will start small, but more and more functionalities will be added as the season progresses. Keep an eye on the progress by regularly clicking on Infection Radar in the left menu bar of CropFields.",
    "settings_field_selection_tool": "As a farmer, you are often asked to share your plots. With the new field selection tool you can now also share multiple fields at once. Click on the fields icon at the top right and then you can click on multiple fields to put together a handy list. Then send via WhatsApp, Twitter, Facebook or your email. The recipient will see exactly the same list in CropFields as you have sent. If you want, you can also send a Shape file with a handy Excel file.",
    "settings_growth_course": "With our new timeline you can see at a glance the spatial variation of a plot over time. In this way you can discover anomalous spots in a field even faster or select the right satellite image for a task map.",
    "settings_lease": "Share information about the lease of a plot.",
    "settings_precip_deficit": "The rainfall deficit visualizes how dry it is in various places in the country.",
    "settings_shadow_map": "The shade map indicates on each crop plot how much shade falls on the plot during the growing season. You can use this information, for example, to adjust the row spacing in the shaded areas or to analyze afterwards why the yield on certain parts of the field lags behind the rest.",
    "settings_soil_potency_map": "With the soil potential map we show which parts of a plot are structurally performing better or less. We do this by superimposing satellite images from the last 5 years. In this way we discover patterns and remove seasonal influences. You can use this map, for example, to make a fertilization plan or to see whether measures are needed that can improve the soil structure.",
    "show trenches, ditch sides": "show embankment, ditch banks",
    "subscribe here to CropFields": "subscribe here to CropFields",
    "the CropFields subscription": "the CropFields subscription",
    "the nature collective": "the nature collective",
    "this blog": "this blog",
    "this farm": "this farm",
    "this field": "this field",
    "this field selection": "this field selection",
    "type": "type",
    "waterways": "water ways",
    "{{days}} days remaining": "{{days}} days remaining",
    "{{fields}} Fields": "{{fields}} Fields",
    "{{hitsCount}} results": "{{hitsCount}} results",
    "{{percentage}}% chance on {{subject}}": "{{percentage}}% chance on {{subject}}"
}},
"fr": {translation: {
    "<2ha, 2-4ha, 4-10ha and 10+ ha": "<2 ha, entre 2-4 ha, 4 à 10 ha et 10 +ha",
    "<a href=\"/authorize\">Login</a> to CropFields to view the cadastral field mapping": "<a href=\"/authorize\">Connectez-vous à Fermier&Champ</a> pour afficher la cartographie des champs cadastraux",
    "<a href=\"{{href}}\">Subscribe</a> to CropFields to view the cadastral field mapping": "<a href=\"{{href}}\">Abonnez-vous</a> à Fermier&Champ pour voir la cartographie des champs cadastraux",
    "<a href=\"{{href}}\">Subscribe</a> to CropFields to view the water information.": "<a href=\"{{href}}\">Abonnez-vous</a> à Fermier&Champ pour afficher les informations sur l'eau.",
    "About CropFields": "À propos",
    "About the farm": "A propos de la ferme",
    "Account": "Compte",
    "AccountFeatures": "<li>Regardez de nombreuses années en arrière dans la rotation des cultures</li>\n<li>Planificateur d'itinéraire et données de terrain exactes</li>\n<li>Consulter les données cadastrales</li>\n<li>Voir les détails des données satellites</li>\n<li>Cartes d'altitude de différents moments</li>\n<li>Faire un rapport du champ de culture (PDF)</li>\n<li>Exporter les données de champ (Shapefile)</li>\n<li>Filtrer les champs par toutes les caractéristiques</li>\n<li>Profitez de toutes les fonctionnalités bêta disponibles</li>",
    "Active sessions": "Sessions actives",
    "Add to list": "Ajouter à la liste",
    "Add your own plots": "Ajoutez vos propres parcelles",
    "Address": "Adresse",
    "Agricultural area": "Zone agricole",
    "All": "Tout",
    "Altitude": "Altitude",
    "Always know the exact cultivable area of fields for planning and invoicing": "Toujours connaître la surface cultivable précise des parcelles pour la planification et la facturation",
    "Analysis": "Analyse",
    "Apple": "Pomme",
    "Arable land": "Terres arables",
    "Are you sure?": "Êtes-vous sûr ?",
    "Area": "Sfc",
    "Asparagus": "Asperge",
    "Attention! This export only contains a part of the search result": "Notez s.v.p que l'exportation ne contient qu'une partie des résultats de la recherche",
    "Attributes": "Attributes",
    "Author": "Auteur",
    "BETA": "BÊTA",
    "Back": "Retour",
    "Back to CropFields": "Retour à Fermier&Champ",
    "Bans": "Légumineuse",
    "Barley": "Orge",
    "Bean": "Haricot",
    "Beet": "Betterave",
    "Berry": "Baie",
    "Beta": "Bêta",
    "Birds and Habitats Directive": "Directive oiseaux et habitats",
    "Birds directive": "Directive oiseaux",
    "Blog": "Blog",
    "Blog unavailable": "Blog non disponible",
    "Broccoli": "Brocolli",
    "Buffer zone": "Zone tampon",
    "Building": "Imeuble",
    "Bulb": "Bulbe à fleurs",
    "By using this website you agree to the terms above": "En utilisant cette application, vous acceptez les conditions ci-dessus",
    "Cabbage": "Chou",
    "Cadastral field": "Parcelle cadastrale",
    "Cadastre": "Cadastre",
    "Cadastre data unknown": "Cadastre inconnu",
    "Cancel": "Annuler",
    "Capsicum": "Poivron",
    "Carrot": "Carotte",
    "Cauliflower": "Chou-fleur",
    "Cherry": "Cerise",
    "Chicory": "Chicorée",
    "Chicory root": "Racine de chicorée",
    "Choose file": "Choisir le fichier",
    "City": "Ville",
    "Clay": "Argile",
    "Clay-Loam": "Argilo-Loam",
    "Clear filters": "Enlever les filtres",
    "Click here": "Cliquez ici",
    "Click on the map to expand the measurement": "Cliquez sur la carte pour agrandir la mesure",
    "Click on the map to expand the measurement, or click on the last point to finish the measurement": "Cliquez sur la carte pour agrandir la mesure ou cliquez sur le dernier point pour arrêter la mesure",
    "Click to start measurement": "Cliquez pour commencer à mesurer",
    "Close": "Fermer",
    "Clover": "Trèfle",
    "Company": "Entreprise",
    "Configure settings": "Configurer fonctionnalités",
    "Connect to RVO": "Connexion à RVO",
    "Contact": "Contact",
    "Continue without cookies": "Continuer avec uniquement les cookies nécessaires",
    "Coordinates": "Coordonnées",
    "Corn": "Maïs",
    "Could not refresh": "L'actualisation de la page a échoué",
    "Country choice": "Choix du pays",
    "Create a free account or login to use this functionality": "Créez un compte gratuit ou connectez-vous pour utiliser cette fonctionnalité",
    "Create an account": "Créer un compte",
    "Create and share your own field lists": "Créer et partager vos propres listes de parcelles",
    "Create field report": "Créer un rapport de parcelle",
    "Create prescription maps with Cloudfarm": "Créer une carte de prescription avec cloudfarm",
    "Crop": "Culture",
    "Crop Predictor": "Prédicteur de récolte",
    "Crop per year": "Cultures par an",
    "Crop rotation": "Rotation des cultures",
    "CropFields": "fermieretchamp",
    "CropFields blog": "Blog Fermier&Champ",
    "CropFields crop prediction for {{year}}": "Prévision Fermier&amp;Champ de culture pour {{year}}",
    "CropFields link": "Lien Fermier&Champ",
    "Cucumber": "Concombre",
    "Custom lists": "Listes personnalisées",
    "Dacom is building a number of new features for CropFields. Anyone with a PRO or higher can try out these features for themselves.": "Dacom travaille à la création d'un certain nombre de nouvelles fonctionnalités pour Fermier&Champ. Toute personne possédant un PRO ou supérieur peut essayer ces fonctionnalités par elle-même.",
    "Deficit": "Déficit",
    "Delete RVO account": "Supprimer compte RVO",
    "Discover": "À découvrir",
    "Ditches": "Fossés",
    "Ditches on map": "Fossés sur carte",
    "Do you want more information about the Pro subscription? Then click <a href=\"{{url}}\">here</a>.": "Vous souhaitez plus d'informations sur l'abonnement Pro? Cliquez ensuite <a href=\"{{url}}\">ici</a>.",
    "Do you want to use CropFields with multiple users in your company? Please contact us via <a href=\"mailto:info@dacom.nl\">info@dacom.nl</a>.": "Voulez-vous utiliser Fermier&Champ avec plusieurs utilisateurs dans votre entreprise? Veuillez nous contacter via <a href=\"mailto:info@dacom.nl\">info@dacom.nl</a>.",
    "Download Geotiff": "Télécharger Geotiff",
    "Download Shapefile": "Télécharger Shapefile",
    "Drainage map": "Plan de drainage",
    "Edit": "Éditer",
    "Edit list": "Liste d'édition",
    "Edit my profile": "Editer mon profil",
    "Edit name": "Modifier le nom",
    "Edit plots": "Modifier des parcelles",
    "Edit your profile and choose your settings.": "Modifiez votre profil et choisissez vos paramètres.",
    "Egg plant": "Aubergine",
    "Email": "Email",
    "Empty": "Jachère",
    "Enter a valid email address": "Entrez une adresse email valide",
    "Enter company name": "Entrez le nom de votre entreprise",
    "Enter your name": "Entrez votre nom",
    "Erosion": "Érosion",
    "Erosion sensitivity": "Sensibilité à l'érosion",
    "Error occurred": "Erreur est survenue",
    "Export capacity after this export": "Espace restant après cette exportation",
    "Export field data to shape file": "Exporter les données de la parcelle vers un fichier de formes",
    "Export {{count}} fields": "Exporter {{count}} champs",
    "Exported field count": "Déjà exporté",
    "Exported fields": "Parcelles exportées",
    "Facts": "Attributes",
    "Failed to change account": "Le changement de compte a échoué",
    "Failed to create favorite": "Marquer comme favori a échoué",
    "Failed to delete selected list": "Échec de la suppression de la liste sélectionnée",
    "Failed to edit name": "Échec de la modification du nom",
    "Failed to fetch farm plots": "L'importation des parcelles de la ferme a échoué",
    "Failed to fetch farms": "L'importation des fermes a échoué",
    "Failed to make farm public": "Échec de la publication de la ferme",
    "Failed to make list public": "Échec de la publication de la liste",
    "Failed to send mail, sorry": "Désolé, l'envoi du message a échoué",
    "Failed to show shared field": "Échec de l'affichage du champ partagé",
    "Failed to show shared list": "Échec de l'affichage de la liste partagée",
    "Farm": "Fermier",
    "Farm overview": "Aperçu de la ferme",
    "Farm profile": "Profil de la ferme",
    "Favorite": "Favori",
    "Feedback": "Retour d'information",
    "Fetch data": "Importer des données",
    "Fetching data": "Données en cours de récupération",
    "Field": "Parcelle",
    "Field export capacity": "Espace d'exportation des parcelles",
    "Field export quota": "Exportation des espaces de parcelles",
    "Field report": "Rapport des parcelles",
    "Field selection": "Sélection de champ",
    "Field selection tool": "Outil de sélection de champ",
    "Field size": "Taille de la parcelle",
    "Fields": "Parcelles",
    "Fields in this export": "Parcelles dans cette exportation",
    "Filter": "Filtrer",
    "Find and quickly navigate to fields": "Trouver et accéder rapidement aux champs",
    "Find suitable land for renting or purchasing": "Ou trouver un terrain à louer ou à acheter avec un sol adapté",
    "Flower": "Fleur",
    "Focus area nitrate": "Zone focale nitrate",
    "Follow on social media": "A suivre sur les réseaux sociaux",
    "Follow satellite data": "Suivre les détails des données satellite",
    "For more information, see": "Pour plus d'informations, voir",
    "For only €16/month you will get all the functionalities of CropFields Pro again": "Pour seulement 13€/mois vous retrouverez toutes les fonctionnalités de Fermier&Champ Pro",
    "Forbidden": "Interdit",
    "Forbidden irrigation": "Interdiction d'irrigation",
    "Forest": "Forêt",
    "Free Pro": "Pro gratuit",
    "Free Pro expired": "Pro gratuit terminé",
    "Free trial": "Essai gratuit",
    "French bean": "Haricot vert",
    "Fruit": "Fruits",
    "GPS coordinates": "Coordonnées GPS",
    "Government property": "Propriété du gouvernement",
    "Grain": "Grain",
    "Grape": "Raisin",
    "Grass": "Herbe",
    "Grass land": "Pâturage",
    "Ground lease": "Bail foncier",
    "Ground water": "Eaux souterraines",
    "Ground water protection": "Protection des eaux souterraines",
    "Ground water protection area": "Zone de protection des eaux souterraines",
    "Growth": "Croissance",
    "Growth course": "Cours de croissance",
    "Growth potential": "Potentiel de croissance",
    "Growth with satellite images": "Croissance par images satellites",
    "Habitat directive": "Directive habitats",
    "Head cabbage": "Chou pommé",
    "Height maps measured on different times": "Cartes d'altitude à des moments différents",
    "Hemp": "Chanvre",
    "Herbs": "Herbes",
    "I accept": "J'accepte",
    "I want to share {{subject}}": "Je veux partager {{subject}}",
    "If you have any questions or comments, email us at": "Si vous avez des questions ou des commentaires, écrivez-nous à",
    "Infection chance": "Risque d'infection",
    "Infection chance potato fungus": "Risque d'infection par le champignon de la pomme de terre",
    "Infection radar": "Radar d'infection",
    "Initialising": "Initialiser",
    "Institute": "Institut",
    "Irrigation limit": "Limitation irrigation",
    "It concerns the first {{count}} fields measured from the center of the map (GPS location {{gps}})": "Il s'agit des premiers {{count}} champs mesurés à partir du centre de la carte (localisation GPS {{gps}})",
    "Large-small": "Grand-petit",
    "Layers": "Les couches",
    "Lease": "Bail",
    "Lease list": "Liste des baux",
    "Lease status unknown": "Statut du bail inconnu",
    "Leek": "Poireau",
    "Leeks": "Poireaux",
    "Less": "Moins",
    "Lettuce": "Laitue",
    "Liked fields": "Champs aimés",
    "Limited": "Limité",
    "List from {{name}}": "Liste de {{name}}",
    "List has been uploaded": "La liste a été téléchargée",
    "Lists": "Listes",
    "Lists overview": "Aperçu des listes",
    "Little shadow": "Petite ombre",
    "Live preview": "Aperçu en direct",
    "Loading": "En cours de chargement",
    "Loading blog": "Charger le blog",
    "Loam": "Limon",
    "Loamy Sand": "Sable Loameux",
    "Location": "lieu",
    "Location not found": "Votre emplacement n'a pas été trouvé.",
    "Log out": "Déconnexion",
    "Logged in as": "Connecté en tant que",
    "Login": "Connexion",
    "Login or create an account": "Connectez-vous ou créez un compte",
    "Login to like farms and plots": "Connectez-vous pour likez les fermes et les champs.",
    "Long lease": "Bail à long terme",
    "Mail service@dacom.nl": "Mail service@dacom.nl",
    "Make farm profile public": "Rendre public le profil de la ferme",
    "Make one of my lists public": "Rendre publique une de mes listes",
    "Make selection smaller": "Réduire la sélection",
    "Make your farm and fields visible for all CropFields users.": "Rendez votre ferme trouvable sur F&C. Plus de visiteurs, contact avec d'autres agriculteurs et plus encore.",
    "Manage public profile": "Rendre publique",
    "Manage sessions": "Gérer les sessions",
    "Map": "Carte",
    "Max annual fields export": "Max parcelles exportées",
    "Max fields in single export": "Max parcelles exportées à la fois",
    "Maximum reached, upgrade to Premium": "Maximum atteint, mise à niveau vers Premium",
    "Measure distance": "Mesurer la distance",
    "Message": "Message",
    "Millet": "Millet",
    "More": "Plus",
    "More information": "Voir plus d'informations",
    "More information about our subscriptions": "En savoir plus sur nos abonnements",
    "More news": "Plus d'infos",
    "More years": "Plus d’années",
    "Much shadow": "Beaucoup d'ombre",
    "Municipality": "Commune",
    "My lists": "Mes listes",
    "Name": "Nom",
    "Natura 2000": "Natura 2000",
    "Nature": "Nature",
    "Nature conservation": "Gestion de la nature",
    "New list": "Nouvelle liste",
    "Next page": "Page suivante",
    "Nitrate": "Nitrate",
    "Nitrate area": "Zone de nitrates",
    "No CropFields subscription yet": "Il n'y a pas encore d'abonnement F&C",
    "No data": "Aucune donnée",
    "No data available, please try again later": "Il n'y a pas de données disponibles pour le moment. Réessayez plus tard.",
    "No information found for this field": "Aucune information trouvée pour cette parcelle",
    "No nature data known": "Nature inconnue",
    "No potatoes for {{year}} years": "Pas de pommes de terre pendant {{year}} ans",
    "No results": "Aucun résultat",
    "No shadow": "Pas d'ombre",
    "No soil data available": "Infos du sol indisponible",
    "Not within Natura 2000": "Ne relève pas de Natura 2000",
    "Number in group": "Nombre dans le groupe",
    "Nut": "Noix",
    "Oats": "Avoine",
    "Onion": "Oignon",
    "Or use our contact form under": "Ou utilisez notre formulaire de contact sous",
    "Order Pro": "Commander Pro",
    "Order a subscription": "Commander un abonnement",
    "Organic": "Biologique",
    "Orthophoto": "Photo aérienne",
    "Other attributes": "Autres attributes",
    "Other lease": "Autre bail",
    "Overlap": "Chevauchement",
    "Owned": "Propriété",
    "Ownership": "Propriété",
    "PRO subscribers can configure additional features here": "Les abonnés PRO peuvent configurer des fonctionnalités supplémentaires ici",
    "Page not found": "Page introuvable",
    "Pasture": "Pâturages",
    "Pea": "Pois",
    "Pear": "Poire",
    "Percentage": "Pourcentage",
    "Period": "Période",
    "Permanent grassland": "Prairies permanentes",
    "Photos": "Photos",
    "Pine tree": "Pin",
    "Plan": "Plan",
    "Plans": "Des plans",
    "Plot name": "Nom du champ",
    "Plots": "Champs",
    "Potato": "Pomme de terre",
    "Potato limit": "Limitation pommes de terre",
    "Precipitation deficit": "Déficit pluviométrique",
    "Preliminary": "Préliminaire",
    "Premium": "Premium",
    "Prescription map": "Carte de prescription",
    "Previous page": "Page précédente",
    "Print field": "Champ d'impression",
    "Pro": "Pro",
    "Profile": "Profil",
    "Province": "Province",
    "Public": "Public",
    "Public Plots": "Parcelles publiques",
    "Pumpkin": "Citrouille",
    "RVO account valid until": "Compte RVO valide jusqu'au",
    "Radar": "Radar",
    "Radish": "Radis",
    "Random": "Au hasard",
    "Rapeseed": "Colza",
    "Read more": "Lire la suite",
    "Reed": "Roseau",
    "Reet": "Roseau",
    "Refresh": "Actualiser",
    "Refresh RVO data": "Rafraîchir les données RVO",
    "Refresh own data": "Actualiser ses propres données",
    "Reg soil type": "Type de sol regulatoire",
    "Regulatory": "Réglementaire",
    "Regulatory soil type": "Type de sol réglementaire",
    "Remove": "Éliminer",
    "Report": "Rapport",
    "Reset all filters": "Réinitialiser tous les filtres",
    "Review many years with a subscription": "Abonnez-vous pour voir les infos des années précédentes",
    "Rice": "Riz",
    "Rose": "Rose",
    "Route planner and precise field information": "Planificateur d'itinéraire et données exactes des parcelles",
    "Rye": "Seigle",
    "Rye-grass": "Ray-grass",
    "Sand": "Sable",
    "Sandy Clay": "Argile Sableuse",
    "Sandy Clay-Loam": "Argilo-Limoneux Sableux",
    "Sandy Loam": "Loam Sableux",
    "Satellite image date": "Date de l'image satellite",
    "Search": "Chercher",
    "Search fields by attribute throughout the country": "Chercher des parcelles selon critères dans tout le pays",
    "Search filter": "Filtre de recherche",
    "Search filter exports": "Exportation des filtres de recherche",
    "Search for address, city, zip, GPS or XY": "Recherche par adresse, ville, GPS ou XY",
    "See <a href=\"{{href}}\">{{description}}</a> for more information": "Voir <a href=\"{{href}}\">{{description}}</a> pour plus d'informations",
    "Select a list to view, add or remove the plots in it.": "Sélectionnez une liste pour afficher, ajouter ou supprimer les champs qu'elle contient.",
    "Selected list was deleted": "La liste sélectionnée a été supprimée",
    "Selected plots": "Champs sélectionnées",
    "Selection": "Sélection",
    "Send": "Envoyer",
    "Send a question or remark": "Envoyez une question ou remarque",
    "Sensitivity": "Sensibilité",
    "Session logged out due to inactivity or remote logout": "Déconnexion suite à une inactivité ou à une connexion ailleurs",
    "Set address": "Saisissez-y votre adresse",
    "Settings": "Paramètres",
    "Shadow": "Ombre",
    "Shadow map": "Carte d'ombre",
    "Share": "Partager",
    "Share {{subject}}": "Partager {{subject}}",
    "Shared list": "Liste partagée",
    "Short lease": "Bail à court terme",
    "Show drainage lines": "Afficher les lignes de drainage",
    "Show less": "Montrer moins",
    "Show shadows": "Afficher la carte d'ombre",
    "Show soil potential": "Montrer le potentiel du sol",
    "Show trenches": "Afficher les berges",
    "Silt": "Limon",
    "Silty Clay-Loam": "Limon Argilo-Limoneux",
    "Small-large": "Petit-grand",
    "Socials": "Sociales",
    "Soil Potential Map": "Carte potentiel du sol",
    "Soil type": "Type de sol",
    "Some shadow": "Un peu d'ombre",
    "Source": "Source",
    "Specialty filter": "Filtre spécialisé",
    "Spinach": "Épinard",
    "Start with precision farming, with insight into altitude maps and satellite images": "Se lancer dans l’agriculture de précision avec des cartes d’élévation et des images satellites",
    "Stop editing": "Arrêter l'édition",
    "Strawberry": "Fraise",
    "Subscribe": "S'abonner",
    "Subscribe for all filter options": "Prenez un abonnement pour avoir plus d'options de filtrage",
    "Subscribe for filtering on <i>{{attr}}</i>": "Prenez un abonnement pour pouvoir filtrer par <i>{{attr}}</i>",
    "Subscribe to CropFields to view all satellite images": "Prenez un abonnement pour voir toutes les images satellites sur la carte",
    "Subscribe to use all options": "Prenez un abonnement pour voir toutes les options",
    "Subscribe to use this functionality": "Abonnez-vous pour utiliser cette fonctionnalité",
    "Subscribe to view all available height data sources": "Prenez un abonnement pour voir l'altitude de toutes les années disponibles",
    "Subscribe to view the exact area": "Prenez un abonnement pour voir la surface exacte",
    "Subscribe to view this data. CropFields is a website for farmers. It is used by many users for free. This is great, but we would like to make the site even better. Help us by <a href=\"{{url}}\">subscribing to CropFields</a>.": "Abonnez-vous pour voir ces données. Fermier&Champ est un site Web pour les agriculteurs. Il est utilisé gratuitement par de nombreux utilisateurs. C'est super, mais nous aimerions rendre le site encore meilleur. Aidez-nous en <a href=\"{{url}}\">en vous abonnant à Fermier&Champ</a>.",
    "SubscribeToSeeSharedData": "<a href=\"{{href}}\">Abonnez-vous</a> à F&C pour voir la sélection partagée. Si vous avez déjà un abonnement, connectez-vous et activez l'option de l'outil de sélection de champ sous Paramètres.",
    "Subscription": "Abonnement",
    "Subscriptions": "Abonnements",
    "Sugar beet": "Betterave sucrière",
    "Summer rye": "Seigle d'été",
    "Sunflower": "Tournesol",
    "Sustainable lease conditions": "Conditions de location durables",
    "Switch to": "Passer sur",
    "Tel +31 88 3226600": "-",
    "Tell me more about the subscriptions": "Information sur les abonnements",
    "Terms": "Conditions",
    "TextAllImagesPro": "Abonnez-vous pour voir toutes les images disponibles sur la carte. Sans Fermier&Champ Pro, une seule image de démonstration (assez récente) est affichée sur la carte. Avec un abonnement, tous les 'points' sont cliquables et chaque image par tracé peut être vue sur la carte.",
    "TextBetterUse": "pour mieux utiliser Fermier&Champ. Avec un abonnement, vous pouvez enregistrer des tracés pour plus tard, afficher des couches de haut niveau et utiliser une fonctionnalité de recherche étendue. Vous pouvez également imprimer et exporter des tracés dans un fichier de formes et d'autres formats.",
    "TextCookies": "De plus, Fermier&Champ utilise des cookies. Nous utilisons des cookies pour réaliser des statistiques (anonymisées en fonction de l'adresse IP) et pour optimiser l'expérience utilisateur. Les cookies ne sont pas partagés avec des tiers et après {{cookieDays}} jours, les cookies sont automatiquement supprimés. En savoir plus à ce sujet dans nos <a href=\"{{termsAndConditionsUrl}}\">conditions générales</a> et notre <a href=\"{{privacyStatementUrl}}\">déclaration de confidentialité</a>.",
    "TextDownloadSearchFilter": "Téléchargez les parcelles depuis votre filtre de recherche. Les tracés sont regroupés avec des attributs dans un fichier Excel et un fichier de formes.",
    "TextFieldsInView": "Donc, avec un seul clic vous avez un aperçu clair des parcelles qui vous intéressent",
    "TextLetUsKnow": "Laissez-nous entendre ce que vous pensez de Fermier&Champ. Le développement continu de Fermier&Champ est motivé par les nombreuses questions des utilisateurs. Avez-vous une question ou une remarque? Nous aimerions avoir de vos nouvelles.",
    "TextLikeFields": "Voici un aperçu des parcelles que vous suivez. Pour suivre un but, vous pouvez en sélectionner un sur la carte et cliquer sur le coeur.",
    "TextNationalLayer": "Avec un abonnement Fermier&Champ, vous pouvez voir {{layer}} au niveau national avec des attributs dans un fichier Excel et un Shapefile.",
    "TextSettingsInfo": "Configurez vos paramètres ici. En tant qu'utilisateur payant, vous pouvez activer des fonctionnalités spécifiques et tirer le meilleur parti du site. Essayez-les un par un et dites-nous ce que vous en pensez",
    "TextTerms": "Fermier&Champ met à votre disposition des données ouvertes. Celles-ci proviennent de diverses sources libres rendues publiques par diverses instances gouvernementales. Dacom ne peut donc donner aucune garantie sur la qualité ou l'exactitude de ces données. Nous faisons de notre mieux pour vous afficher les informations les plus correctes possibles. Pour l'exactitude et l'actualisation de ces données, veuillez contacter le détenteur de la source. Dans cette application, nous mentionnons autant que possible la source de ces données avec le symbole (i)",
    "TextViewedTooManyPlots": "Vous avez déjà consulté gratuitement {{maxFields}} champs cultivés cette semaine!Nous travaillons dur pour garder Fermier&Champ sans publicité et à jour, pour fournir de bons service et nous continuons à mettre à jour et à ajouter des ensembles de données pertinents. En conséquence, nous avons choisi de proposer aux utilisateurs professionnels un abonnement Pro pour 13 € par mois.",
    "TextViewedTooManyPlotsNoCookies": "Nous nous efforçons de maintenir notre plate-forme indépendante Fermier&Champ sans publicité et à jour, pour fournir un bon service et fournir des ensembles de données plus pertinents. Aidez-nous, en tant qu'utilisateur professionnel, à souscrire à un abonnement Pro pour 13 € par mois.",
    "TextWithAProSubscription": "<p>Avec un abonnement Pro, vous pouvez</p><ul><li>Trouvez rapidement des champs et naviguez-y</li><li>Connaître la surface cultivable exacte des champs cultivés</li><li>Comprendre les rotations de cultures par parcelle pour planifier un schéma de culture</li><li>Utiliser des cartes d'altitude et des images satellite pour l'agriculture de précision</li><li>Utilisez les informations cadastrales pour trouver un terrain approprié à louer ou à vendre</li></ul>",
    "Thanks for you message": "Merci pour votre message",
    "The farm": "La ferme",
    "The list is empty.": "La liste est vide.",
    "The public state of your farm has been changed": "L'état public de votre ferme a été modifié",
    "The public state of your list has been changed": "L'état public de votre liste a été modifié",
    "This field could not be found!": "Ce champ est introuvable!",
    "This field is located within the boundaries of nature collective": "Ce champ est situé dans les limites de la collectif nature",
    "This field is part of habitats": "This field is part of habitats",
    "This field is part of the Nature Network of this country": "Cette parcelle fait partie du Réseau Nature de ce pays",
    "This field is used as <i>{{useTitle}}</i>": "Ce champ est utilisé comme <i>{{useTitle}}</i>",
    "This is a pro feature": "Ceci est une fonctionnalité pro",
    "This plot is owned by state institute <i>{{name}}</i>": "Ce terrain appartient à l'institut d'état <i>{{name}}</i>",
    "This session has expired or has been terminated remotely with a different login": "Cette session a expiré ou s'est terminée suite à une connexion à ce compte sur trop d'appareils",
    "Title": "Titre",
    "Today": "Aujourd'hui",
    "Tomato": "Tomate",
    "Total": "Total",
    "Tree": "Arbre",
    "Trenches": "Tranchées",
    "Tulip": "Tulipe",
    "Unknown": "Inconnu",
    "Unselect plots": "Désélectionner les champs",
    "Upload": "Télécharger",
    "Uploading": "Téléchargement",
    "Uploading this file will overwrite your current lease list": "Le téléchargement de ce fichier écrasera votre liste de location actuelle",
    "Use title": "Type de bail",
    "Vegetables": "Légumes",
    "Video": "Vidéo",
    "View all datasets": "Voir tous les groupes de données",
    "View cadastral data": "Voir les données cadastrales",
    "View crop rotations for creating a healthy cultivation plan": "Consulter les rotations de culture pour dresser un plan de cultures saines et viables",
    "View many historic crop rotation years": "Voir l'historique des cultures d'il y a plusieurs années.",
    "View {{subject}} on CropFields": "Voir {{subject}} sur fermieretchamp",
    "WDVI images": "Images WDVI",
    "Water board": "Agence de l'eau",
    "Water trap": "Escalier d'eau",
    "Website": "Site Internet",
    "With a crop recording subscription, you can work with multiple users on the same company & subscription": "Avec un abonnement Enregistrement des cultures, vous pouvez travailler avec plusieurs personnes de la même entreprise.",
    "With a free account you can use many extra functionalities:": "Avec un compte gratuit, vous pouvez utiliser de nombreuses fonctionnalités supplémentaires:",
    "With the free version of CropFields, you can view the basic data of a plot such as crop group, location and agricultural area.": "Avec la version gratuite de Fermier&Champ, vous pouvez afficher les données de base d'une parcelle telles que la culture, l'emplacement et la zone agricole.",
    "Without a subscription, you can view data of {{maxFields}} crop fields per week for free.": "Sans abonnement, vous pouvez consulter gratuitement les données de {{maxFields}} champs de culture par semaine.",
    "Would you like to view additional crop fields?": "Souhaitez-vous afficher des champs supplémentaires?",
    "XY coordinates": "Coordonnées XY",
    "Yes, make my farm profile public": "Oui, rendre mon profil de ferme public",
    "Yes, make one of my lists public": "Oui, rendre publique une de mes listes",
    "You can be logged in on up to two devices": "Vous pouvez vous connecter au même compte sur un maximum de deux appareils",
    "Your contact information is public to other users.": "Vos coordonnées sont publiques pour les autres utilisateurs.",
    "Your one-month trial subscription has expired": "Votre abonnement d’essai d’un mois a expiré",
    "Your question or remark": "Votre question ou remarque",
    "Zip code": "Code postal",
    "Zipcode/city": "Code postal /ville",
    "all images": "toutes les images",
    "average": "moyen",
    "avg": "moy",
    "by": "par",
    "crop rotation": "cultures des années précédentess",
    "difference": "différence",
    "different attributes": "attributs différents",
    "different soil types": "différents types de sol",
    "error": "erreur",
    "example": "exemple",
    "fields": "parcelles",
    "filterTextAccount": "Créez un compte ou connectez-vous pour utiliser toutes les options de filtrage",
    "get a free trial subscription to CropFields": "obtenez un abonnement d'essai gratuit à Fermier&Champ",
    "heighest": "le plus haut",
    "l_cadastre_p1": "Cadastre p1",
    "l_cadastre_p2": "Cadastre p2",
    "l_info_bufferzones": "Une bande tampon est une bande de terrain sur laquelle il est interdit d'utiliser du fumier, des produits phytosanitaires chimiques ou des biocides. Cela protège la qualité de l'eau et stimule la biodiversité. Une bande riveraine est située le long d'un cours d'eau longeant des terres agricoles.",
    "l_info_ews": "Le radar d'infection vous permet d'identifier les risques imminents de maladies sur vos cultures.",
    "l_info_facts": "Les informations sur chaque parcelle sont collectées via plusieurs sources de données,",
    "l_info_growth": "Carte de végétation actuelle obtenue à partir d'images satellites. Parexemple, suivez quand par parcelle est semé et récolté.<br />Source: <a href=\"https://copernicus.eu/\">copernicus.eu</a>",
    "l_info_height": "Carte d'altitude numérique. Un abonnement Fermier&Champ permet plusieurs versions.",
    "l_info_lease": "Proposez-vous ce terrain à la location et souhaitez-vous dire comment vous stimulez les activités durables ? Consultez <a href=\"/page/lease\">bail</a> pour plus d'informations.",
    "l_info_nature": "Natura 2000. Le réseau Natura 2000 rassemble des sites naturels ou semi-naturels del'Union européenne ayant une grande valeur patrimoniale, par la faune et la floreexceptionnelles qu'ils contiennent. <br />Regarde aussie <a href=\"https://ec.europa.eu/environment/nature/natura2000/faq_fr.htm\">information de la commission européenne</a>.",
    "l_info_property": "Parcelles cadastrales collectées auprès du cadastre local. Ces parcelles délimitent les limites de propriété.",
    "l_info_soil": "Classification du type de sol",
    "l_info_use_title": "Ici, nous montrons le titre d'utilisation tel que RVO l'a collecté dans la déclaration combinée. Ces données n'ont PAS été vérifiées par le gouvernement, elles sont indicatives et aucun droit ne peut en être dérivé. Il y a un décalage entre les parcelles du gouvernement et les sections cadastrales qui ont un titre d'utilisation spécifique.",
    "l_info_water": "Fossés et cours d'eau",
    "l_info_weather": "Déficit de précipitations",
    "legend": "légende",
    "length": "longueur",
    "lowest": "le plus bas",
    "max": "max",
    "meter": "mètre",
    "min": "min",
    "more years": "plus d'années",
    "nr": "n°",
    "or": "ou",
    "province and municipality borders": "frontières de province et de commune",
    "restrictedAccount": "Créez un compte gratuit ou connectez-vous pour utiliser cette fonctionnalité",
    "settings_WDVI_images": "Un inconvénient du NDVI est que plus tard dans la saison, les images satellites ne peuvent plus faire la différence entre une récolte complète et une récolte très complète. Les images WDVI en souffrent moins. Cet indice de croissance s'intéresse également au reflet du sol nu. Cela améliore le contraste.",
    "settings_crop_predictor_feature_warning": "Cette fonctionnalité est désactivée pendant un certain temps. Faites-nous savoir si vous pensez qu'il s'agit d'une option pertinente et qu'elle remontera dans la liste des priorités.",
    "settings_crop_predictor_hanze": "En collaboration avec l'Université Hanze de Groningen, Dacom a développé un modèle permettant de prédire les cultures futures, le Crop Predictor.",
    "settings_crop_predictor_info_1": "Activez la bascule pour que pour chaque parcelle de culture dans Fermier&Champ, vous puissiez voir quelles cultures nous prédisons pour chaque parcelle en 2021 en fonction des rotations de cultures précédentes et des images satellites actuelles.",
    "settings_crop_predictor_info_2": "À l'aide d'une IA et d'algorithmes d'apprentissage en profondeur, le modèle découvre des modèles sous-jacents dans l'ensemble de données. L'entrée du modèle est initialement basée sur la rotation des cultures de toutes les parcelles néerlandaises enregistrées dans le Basis Registratie Percelen pour la période de 2005 à 2020. Les prévisions pour 2021 continueront de s'améliorer au cours de l'année, à mesure que de nouvelles images satellites seront disponibles. venir. Le modèle examine ensuite, par exemple, la courbe de croissance NDVI d'une parcelle et la compare aux années précédentes.",
    "settings_crop_predictor_info_3": "Après avoir activé la bascule, sur Fermier&Champ, nous affichons trois cultures qui correspondent probablement au modèle de chaque parcelle. Un pourcentage élevé pour une culture signifie que le modèle a déjà observé un tel schéma dans les parcelles néerlandaises et que le modèle suppose que cette culture sera très probablement en 2021.",
    "settings_ditches": "Fossés et cours d'eau",
    "settings_drainage_map": "La carte de drainage est basée sur la carte d'élévation la plus récente. Sur la carte, vous pouvez voir où l'eau coule. Vous pouvez utiliser cette carte, par exemple, pour analyser l'érosion sur votre parcelle ou pour creuser des tranchées en cas d'inondation.",
    "settings_ews_feature": "Avec le système d'alerte précocede Dacom, vous recevez des informations en temps opportun sur le risque d'infection d'environ 20 maladies fongiques. Les modèles Dacom couvrent ainsi les principales cultures cultivées aux Pays-Bas. Au départ, nous commencerons petit, mais de plus en plus de fonctionnalités seront ajoutées au fur et à mesure que la saison avance. Gardez un œil sur les progrès en cliquant régulièrement sur Radar dans la barre de menu de gauche de Fermier&Champ.",
    "settings_field_selection_tool": "En tant qu'agriculteur, on vous demande souvent de partager vos parcelles. Avec le nouvel outil de sélection de champs, vous pouvez désormais également partager plusieurs champs à la fois. Cliquez sur l'icône des champs en haut à droite, puis vous pouvez cliquer sur plusieurs champs pour constituer une liste pratique. Envoyez ensuite via WhatsApp, Twitter, Facebook ou votre e-mail. Le destinataire verra exactement la même liste dans Fermier&Champ que celle que vous avez envoyée. Si vous le souhaitez, vous pouvez également envoyer un fichier Shape avec un fichier Excel pratique.",
    "settings_growth_course": "Avec notre nouvelle chronologie, vous pouvez voir en un coup d'œil la variation spatiale d'une parcelle au fil du temps. De cette façon, vous pouvez découvrir encore plus rapidement des points anormaux dans un champ ou sélectionner la bonne image satellite pour une carte des tâches.",
    "settings_lease": "Partagez vous-même des informations sur la location d'un terrain.",
    "settings_precip_deficit": "Le déficit pluviométrique permet de visualiser à quel point il fait sec dans divers endroits du pays.",
    "settings_shadow_map": "La carte d'ombrage indique sur chaque parcelle cultivée la quantité d'ombre qui tombe sur la parcelle pendant la saison de croissance. Vous pouvez utiliser ces informations, par exemple, pour ajuster l'espacement des rangs dans les zones ombrées ou pour analyser par la suite pourquoi le rendement sur certaines parties du champ est en retard par rapport au reste.",
    "settings_soil_potency_map": "Avec la carte potentiel du sol, nous montrons quelles parties d'une parcelle sont structurellement plus ou moins performantes. Nous le faisons en superposant des images satellites des 5 dernières années. De cette façon, nous découvrons des modèles et supprimons les influences saisonnières. Vous pouvez utiliser cette carte, par exemple, pour faire un plan de fertilisation ou pour voir si des mesures sont nécessaires pour améliorer la structure du sol.",
    "show trenches, ditch sides": "afficher les berges, les rives de canaux",
    "subscribe here to CropFields": "prenez directement un abonnement Fermier&Champs ici.",
    "the CropFields subscription": "l'abonnement Fermier&Champ",
    "the nature collective": "le collectif nature",
    "this blog": "ce blog",
    "this farm": "cette ferme",
    "this field": "cette parcelle",
    "this field selection": "cette sélection de champ",
    "type": "type",
    "waterways": "voies navigables",
    "{{days}} days remaining": "Encore {{days}} jours",
    "{{fields}} Fields": "{{fields}} Parcelles",
    "{{hitsCount}} results": "{{hitsCount}} résultats",
    "{{percentage}}% chance on {{subject}}": "{{percentage}}% de risque sur {{subject}}"
}},
"nl": {translation: {
    "<2ha, 2-4ha, 4-10ha and 10+ ha": "<2ha, tussen 2-4ha, 4 tot 10ha en 10+ ha",
    "<a href=\"/authorize\">Login</a> to CropFields to view the cadastral field mapping": "<a href=\"/authorize\">Login</a> op Boer&Bunder om de kadastrale percelen te bekijken",
    "<a href=\"{{href}}\">Subscribe</a> to CropFields to view the cadastral field mapping": "<a href=\"{{href}}\">Neem een abonnement</a> op Boer&Bunder om de kadastrale percelen te bekijken",
    "<a href=\"{{href}}\">Subscribe</a> to CropFields to view the water information.": "<a href=\"{{href}}\">Neem een abonnement</a> op Boer&Bunder om water data te bekijken.",
    "API": "API",
    "About": "Over",
    "About API": "Over API",
    "About CropFields": "Over B&B",
    "About data": "Over data",
    "About the farm": "Over de boerderij",
    "Account": "Account",
    "AccountFeatures": "<li> Vele jaren terugkijken in gewasrotatie </li> \n<li> Routeplanner en nauwkeurige veldgegevens </li> \n<li> Zie kadastrale gegevens </li> \n<li> Zie meer details over satellietgegevens </li> \n<li> Hoogtekaart met verschillende momenten </li> \n<li> Maak een veld report (PDF) </li> \n<li> Veldgegevens exporteren (shapefile) </li> \n<li> Filter velden op alle eigenschappen </li> \n<li> Profiteer van alle beschikbare bètafuncties </li>",
    "Active sessions": "Actieve sessies",
    "Add to list": "Toevoegen aan lijst",
    "Add your own plots": "Voeg eigen percelen toe",
    "Address": "Adres",
    "Agricultural area": "Landbouwgebied",
    "All": "Alles",
    "Altitude": "Hoogte",
    "Always know the exact cultivable area of fields for planning and invoicing": "Weet altijd de juiste beteelbare oppervlakte van percelen voor plannen en facturen",
    "Analysis": "Analyse",
    "Apple": "Appel",
    "Arable land": "Bouwland",
    "Are you sure?": "Weet je het zeker?",
    "Area": "Opp",
    "Asparagus": "Asperge",
    "Attention! This export only contains a part of the search result": "Let op! De export bevat maar een deel van het zoekresultaat",
    "Attributes": "Kenmerken",
    "Author": "Auteur",
    "BETA": "BETA",
    "Back": "Terug",
    "Back to CropFields": "Terug naar B&B",
    "Bans": "Peulgroente",
    "Barley": "Gerst",
    "Bean": "Boon",
    "Beet": "Biet",
    "Berry": "Bes",
    "Beta": "Beta",
    "Birds and Habitats Directive": "Vogel- en habitatrichtlijn",
    "Birds directive": "Vogelrichtlijn",
    "Blog": "Blog",
    "Blog unavailable": "Blog niet beschikbaar",
    "Broccoli": "Broccoli",
    "Buffer zone": "Bufferstrook",
    "Building": "Gebouw",
    "Bulb": "Bloembol",
    "By using this website you agree to the terms above": "Bij het gebruiken van deze applicatie accepteer je bovenstaande voorwaarden",
    "Cabbage": "Kool",
    "Cadastral field": "Kadastraal perceel",
    "Cadastre": "Kadaster",
    "Cadastre data unknown": "Geen kadaster bekend",
    "Cancel": "Annuleren",
    "Capsicum": "Paprika",
    "Carrot": "Wortel",
    "Cauliflower": "Bloemkool",
    "Cherry": "Kers",
    "Chicory": "Cichorei",
    "Chicory root": "Cichorei",
    "Choose file": "Kies bestand",
    "City": "Woonplaats",
    "Clay": "Klei",
    "Clay-Loam": "Klei-leem",
    "Clear filters": "Wis filters",
    "Click here": "Klik hier",
    "Click on the map to expand the measurement": "Klik op de kaart om de meting uit te breiden",
    "Click on the map to expand the measurement, or click on the last point to finish the measurement": "Klik op de kaart om de meting uit te breiden, of het laatste punt om de meting te stoppen",
    "Click to start measurement": "Klik om de meting te beginnen",
    "Close": "Sluit",
    "Clover": "Klaver",
    "Company": "Bedrijf",
    "Configure settings": "Instellingen",
    "Connect to RVO": "Koppel met RVO",
    "Contact": "Contact",
    "Continue without cookies": "Ga verder met alleen noodzakelijke cookies",
    "Coordinates": "Coördinaten",
    "Corn": "Maïs",
    "Could not refresh": "Kon niet verversen",
    "Country choice": "Land keuze",
    "Create a free account or login to use this functionality": "Maak een gratis account aan of log in om deze functionaliteit te gebruiken",
    "Create an account": "Account aanmaken",
    "Create and share your own field lists": "Maak en deel je eigen lijstjes van percelen",
    "Create field report": "Maak een perceelsrapport",
    "Create prescription maps with Cloudfarm": "Maak taakkaarten met Cloudfarm",
    "Crop": "Gewas",
    "Crop Predictor": "Gewasvoorspeller",
    "Crop per year": "Gewas per jaar",
    "Crop rotation": "Gewasrotatie",
    "CropFields": "boerenbunder",
    "CropFields blog": "Boer&Bunder blog",
    "CropFields crop prediction for {{year}}": "B&B gewas voorspelling voor {{year}}",
    "CropFields link": "Boer&Bunder link",
    "Cucumber": "Komkommer",
    "Custom lists": "Eigen lijsten",
    "Dacom is building a number of new features for CropFields. Anyone with a PRO or higher can try out these features for themselves.": "Dacom is bezig om een aantal nieuwe features te bouwen voor Boer&Bunder. Iedereen met een PRO of hoger kan deze features zelf uitproberen.",
    "Data": "Data",
    "Deficit": "Tekort",
    "Delete RVO account": "Verwijder RVO account",
    "Discover": "Ontdek",
    "Ditches": "Sloten",
    "Ditches on map": "Sloten op kaart",
    "Do you want more information about the Pro subscription? Then click <a href=\"{{url}}\">here</a>.": "Wil je meer weten over het Pro abonnement? Klik dan <a href=\"{{url}}\">hier</a>.",
    "Do you want to use CropFields with multiple users in your company? Please contact us via <a href=\"mailto:info@dacom.nl\">info@dacom.nl</a>.": "Wil je Boer&Bunder gebruiken met meerdere gebruikers in je bedrijf? Neem dan contact op via <a href=\"mailto:info@dacom.nl\">info@dacom.nl</a>.",
    "Download Geotiff": "Download Geotiff",
    "Download Shapefile": "Download Shapefile",
    "Drainage map": "Afwateringskaart",
    "Edit": "Wijzig",
    "Edit list": "Wijzig lijst",
    "Edit my profile": "Bewerk mijn profiel",
    "Edit name": "Wijzig naam",
    "Edit plots": "Wijzig percelen",
    "Edit your profile and choose your settings.": "Bewerk je profiel en kies je instellingen.",
    "Egg plant": "Aubergine",
    "Email": "E-mail",
    "Empty": "Braak",
    "Enter a valid email address": "Vul een geldig e-mail adres in",
    "Enter company name": "Vul je bedrijfsnaam in",
    "Enter your name": "Vul je naam in",
    "Erosion": "Erosie",
    "Erosion sensitivity": "Erosiegevoeligheid",
    "Error occurred": "Fout opgetreden",
    "Export capacity after this export": "Overgebleven ruimte na deze export",
    "Export field data to shape file": "Exporteer perceelsdata naar Shapefile",
    "Export {{count}} fields": "Exporteer {{count}} percelen",
    "Exported field count": "Eerder geëxporteerde percelen",
    "Exported fields": "Geëxporteerde percelen",
    "Facts": "Kenmerken",
    "Failed to change account": "Account wissel mislukt",
    "Failed to create favorite": "Favoriet maken mislukt",
    "Failed to delete selected list": "De geselecteerde lijst kon niet verwijderd worden",
    "Failed to edit name": "Naam wijzigen mislukt",
    "Failed to fetch farm plots": "Ophalen percelen van boerderij mislukt",
    "Failed to fetch farms": "Ophalen boerderijen mislukt",
    "Failed to make farm public": "Kan boerderij niet publiek maken",
    "Failed to make list public": "Kan lijst niet publiek maken",
    "Failed to send mail, sorry": "Mail versturen mislukt, sorry",
    "Failed to show shared field": "Kan gedeelde bunder niet weergeven",
    "Failed to show shared list": "Kan gedeelde lijst niet weergeven",
    "Farm": "Boerderij",
    "Farm overview": "Boerderijoverzicht",
    "Farm profile": "Boerderijprofiel",
    "Favorite": "Favoriet",
    "Feedback": "Feedback",
    "Fetch data": "Data ophalen",
    "Fetching data": "Data wordt opgehaald",
    "Field": "Perceel",
    "Field export capacity": "Exportruimte percelen",
    "Field export quota": "Export ruimte percelen",
    "Field report": "Perceelsrapport",
    "Field selection": "Veldselectie",
    "Field selection tool": "Veldselectie-tool",
    "Field size": "Perceelsgrootte",
    "Fields": "Percelen",
    "Fields in this export": "Percelen in deze export",
    "Filter": "Filter",
    "Find and quickly navigate to fields": "Vind en navigeer snel naar percelen",
    "Find suitable land for renting or purchasing": "Vind geschikte grond voor huurland of koop",
    "Flower": "Bloem",
    "Focus area nitrate": "Focusgebied nitraat",
    "Follow on social media": "Volg op sociale media",
    "Follow satellite data": "Volg details van satellietdata",
    "For more information, see": "Voor meer informatie zie",
    "For only €16/month you will get all the functionalities of CropFields Pro again": "Voor slechts €16/maand krijg je weer alle functionaliteiten van Boer&Bunder Pro",
    "Forbidden": "Verboden",
    "Forbidden irrigation": "Beregeningsverbod",
    "Forest": "Bos",
    "Free Pro": "Gratis Pro",
    "Free Pro expired": "Gratis Pro verlopen",
    "Free trial": "Proefabonnement",
    "French bean": "Sperzieboon",
    "Fruit": "Fruit",
    "GPS coordinates": "GPS coördinaten",
    "Government property": "Overheidseigendom",
    "Grain": "Graan",
    "Grape": "Druif",
    "Grass": "Gras",
    "Grass land": "Grasland",
    "Ground lease": "Erfpacht",
    "Ground water": "Grondwater",
    "Ground water protection": "Grondwaterbescherming",
    "Ground water protection area": "Grondwaterbeschermingsgebied",
    "Growth": "Groei",
    "Growth course": "Groeiverloop",
    "Growth potential": "Bodempotentie",
    "Growth with satellite images": "Groei via satellietbeelden",
    "Habitat directive": "Habitatrichtlijn",
    "Head cabbage": "Kool",
    "Height maps measured on different times": "Hoogtekaarten van verschillende momenten",
    "Hemp": "Hennep",
    "Herbs": "Kruiden",
    "I accept": "Ik ga akkoord",
    "I want to share {{subject}}": "Ik wil {{subject}} delen",
    "If you have any questions or comments, email us at": "Als je vragen of opmerkingen hebt, mail ons op",
    "Infection chance": "Infectiekans",
    "Infection chance potato fungus": "Infectiekans aardappelziekte",
    "Infection radar": "Infectieradar",
    "Initialising": "Initialiseren",
    "Institute": "Overheidseigendom",
    "Irrigation limit": "Irrigatie beperking",
    "It concerns the first {{count}} fields measured from the center of the map (GPS location {{gps}})": "Het betreft de eerste {{count}} percelen vanaf het middelpunt van de kaart (GPS locatie {{gps}})",
    "Large-small": "Groot-klein",
    "Layers": "Lagen",
    "Lease": "Pacht",
    "Lease list": "Pachtlijst",
    "Lease status unknown": "Pacht niet bekend",
    "Leek": "Prei",
    "Leeks": "Prei",
    "Less": "Minder",
    "Lettuce": "Sla",
    "Liked fields": "Bunders",
    "Limited": "Beperkt",
    "List from {{name}}": "Lijst van {{name}}",
    "List has been uploaded": "Lijst is geüpload",
    "Lists": "Lijsten",
    "Lists overview": "Lijsten overzicht",
    "Little shadow": "Weinig schaduw",
    "Live preview": "Live voorbeeld",
    "Loading": "Laden",
    "Loading blog": "Blog inladen",
    "Loam": "Leem",
    "Loamy Sand": "Leemachtig zand",
    "Location": "Locatie",
    "Location not found": "Je locatie is niet gevonden",
    "Log out": "Uitloggen",
    "Logged in as": "Ingelogd als",
    "Login": "Login",
    "Login or create an account": "Log in of maak een account",
    "Login to like farms and plots": "Log in om boerderijen en bunders te liken",
    "Long lease": "Langdurige pacht",
    "Mail service@dacom.nl": "Mail service@dacom.nl",
    "Make farm profile public": "Maak boerderijprofiel publiek",
    "Make one of my lists public": "Maak een van mijn lijsten publiek",
    "Make selection smaller": "Maak de selectie kleiner",
    "Make your farm and fields visible for all CropFields users.": "Maak je boerderij/percelen zichtbaar voor alle gebruikers op Boer&Bunder.",
    "Manage public profile": "Beheer instelling publiek/privé",
    "Manage sessions": "Beheer sessies",
    "Map": "Kaart",
    "Max annual fields export": "Max export percelen",
    "Max fields in single export": "Max export percelen per keer",
    "Maximum reached, upgrade to Premium": "Max bereikt, upgrade naar Premium",
    "Measure distance": "Meet afstand",
    "Message": "Bericht",
    "Millet": "Gierst",
    "More": "Meer",
    "More information": "Meer informatie",
    "More information about our subscriptions": "Lees meer over onze abonnementen",
    "More news": "Meer nieuws",
    "More years": "Meer jaren",
    "Much shadow": "Veel schaduw",
    "Municipality": "Gemeente",
    "My lists": "Mijn lijsten",
    "Name": "Naam",
    "Natura 2000": "Natura 2000",
    "Nature": "Natuur",
    "Nature conservation": "Natuurbeheer",
    "New list": "Nieuwe lijst",
    "Next page": "Volgende pagina",
    "Nitrate": "Nitraat",
    "Nitrate area": "Nitraatgebiedstype",
    "No CropFields subscription yet": "Nog geen B&B abonnement",
    "No data": "Geen data",
    "No data available, please try again later": "Er is geen beschikbare data op dit moment. Probeer later opnieuw.",
    "No information found for this field": "Geen informatie gevonden van dit perceel",
    "No nature data known": "Geen natuur bekend",
    "No potatoes for {{year}} years": "Geen aardappels sinds {{year}} jaar",
    "No results": "Geen resultaten",
    "No shadow": "Geen schaduw",
    "No soil data available": "Geen bodemdata beschikbaar",
    "Not within Natura 2000": "Valt niet binnen Natura 2000",
    "Number in group": "Aantal in groep",
    "Nut": "Noot",
    "Oats": "Haver",
    "Onion": "Ui",
    "Or use our contact form under": "Of gebruik het contactformulier onder",
    "Order Pro": "Bestel Pro",
    "Order a subscription": "Bestel abonnement",
    "Organic": "Biologisch",
    "Orthophoto": "Luchtfoto",
    "Other attributes": "Overige kenmerken",
    "Other lease": "Overige pacht",
    "Overlap": "Overlap",
    "Owned": "Eigendom",
    "Ownership": "Eigendom",
    "PRO subscribers can configure additional features here": "Hier is extra functionaliteit in te stellen voor abonnees met PRO of hoger",
    "Page not found": "Pagina niet gevonden",
    "Pasture": "Grasland",
    "Pea": "Erwt",
    "Pear": "Peer",
    "Percentage": "Percentage",
    "Period": "Periode",
    "Permanent grassland": "Blijvend grasland",
    "Photos": "Foto's",
    "Pine tree": "Naaldboom",
    "Plan": "Bouwplan",
    "Plans": "Bouwplannen",
    "Plot name": "Perceelsnaam",
    "Plots": "Bunders",
    "Potato": "Aardappel",
    "Potato limit": "Aardappel beperking",
    "Precipitation deficit": "Neerslagtekort",
    "Preliminary": "Voorlopig",
    "Premium": "Premium",
    "Prescription map": "Taakkaart",
    "Previous page": "Vorige pagina",
    "Print field": "Print perceel",
    "Pro": "Pro",
    "Profile": "Profiel",
    "Province": "Provincie",
    "Public": "Publiek",
    "Public Plots": "Publieke percelen",
    "Pumpkin": "Pompoen",
    "RVO account valid until": "RVO account geldig tot",
    "Radar": "Radar",
    "Radish": "Radijs",
    "Random": "Willekeurig",
    "Rapeseed": "Koolzaad",
    "Read more": "Lees meer",
    "Reed": "Riet",
    "Reet": "Riet",
    "Refresh": "Ververs",
    "Refresh RVO data": "Ververs RVO gegevens",
    "Refresh own data": "Ververs eigen gegevens",
    "Reg soil type": "Gronds. mestwet",
    "Regulatory": "Overheid",
    "Regulatory soil type": "Wettelijke grondsoort",
    "Remove": "Verwijder",
    "Report": "Factsheet",
    "Reset all filters": "Reset alle filters",
    "Review many years with a subscription": "Met een abonnement kijk je vele jaren terug",
    "Rice": "Rijst",
    "Rose": "Roos",
    "Route planner and precise field information": "Routeplanner en exacte perceelsgegevens",
    "Rye": "Rogge",
    "Rye-grass": "Raaigras",
    "Sand": "Zand",
    "Sandy Clay": "Zandige klei",
    "Sandy Clay-Loam": "Zandige klei-leem",
    "Sandy Loam": "Zandige leem",
    "Satellite image date": "Datum satellietbeeld",
    "Search": "Zoeken",
    "Search fields by attribute throughout the country": "Zoek percelen op kenmerk in het hele land",
    "Search filter": "Zoekfilter",
    "Search filter exports": "Zoekfilter exports",
    "Search for address, city, zip, GPS or XY": "Zoek op adres, plaats, postcode, GPS of XY",
    "See <a href=\"{{href}}\">{{description}}</a> for more information": "Zie <a href=\"{{href}}\">{{description}}</a> voor meer informatie",
    "Select a list to view, add or remove the plots in it.": "Selecteer een lijst om de bunders erin te bekijken, nieuwe toe te voegen of bestaande te verwijderen.",
    "Selected list was deleted": "Geselecteerde lijst is verwijderd",
    "Selected plots": "Geselecteerde bunders",
    "Selection": "Selectie",
    "Send": "Verstuur",
    "Send a question or remark": "Stuur een vraag of opmerking",
    "Sensitivity": "Gevoeligheid",
    "Session logged out due to inactivity or remote logout": "Uitgelogd door inactiviteit of inlog op andere plek",
    "Set address": "Stel je adres in",
    "Settings": "Instellingen",
    "Shadow": "Schaduw",
    "Shadow map": "Schaduwkaart",
    "Share": "Delen",
    "Share {{subject}}": "Deel {{subject}}",
    "Shared list": "Gedeelde lijst",
    "Short lease": "Kortdurige pacht",
    "Show drainage lines": "Toon afwateringslijnen",
    "Show less": "Toon minder",
    "Show shadows": "Toon schaduwkaart",
    "Show soil potential": "Toon bodempotentie",
    "Show trenches": "Oevers tonen",
    "Silt": "Slib",
    "Silty Clay-Loam": "Zilte klei-leem",
    "Small-large": "Klein-groot",
    "Socials": "Socials",
    "Soil Potential Map": "Bodempotentiekaart",
    "Soil type": "Grondsoort",
    "Some shadow": "Wat schaduw",
    "Source": "Bron",
    "Specialty filter": "Speciale filter",
    "Spinach": "Spinazie",
    "Start with precision farming, with insight into altitude maps and satellite images": "Begin met precisielandbouw met inzicht in hoogtekaarten",
    "Stop editing": "Stop met wijzigen",
    "Strawberry": "Aardbei",
    "Subscribe": "Abonneer",
    "Subscribe for all filter options": "Neem een abonnement voor alle filteropties",
    "Subscribe for filtering on <i>{{attr}}</i>": "Neem een abonnement om op <i>{{attr}}</i> te kunnen filteren",
    "Subscribe to CropFields to view all satellite images": "Neem een abonnement om alle satellietbeelden op de kaart te zien",
    "Subscribe to use all options": "Neem een abonnement op alle opties te bekijken",
    "Subscribe to use this functionality": "Neem een abonnement om van deze functionaliteit gebruik te maken",
    "Subscribe to view all available height data sources": "Neem een abonnement om van alle beschikbare jaren de hoogte te bekijken",
    "Subscribe to view the exact area": "Neem een abonnement om de precieze oppervlakte te zien",
    "Subscribe to view this data. CropFields is a website for farmers. It is used by many users for free. This is great, but we would like to make the site even better. Help us by <a href=\"{{url}}\">subscribing to CropFields</a>.": "Neem een abonnement om deze data te bekijken. Boer&Bunder is een website voor boeren. Er wordt veel gratis gebruik van gemaakt en dat willen we graag mogelijk blijven maken. Help ons als grootgebruiker om de site te blijven verbeteren en <a href=\"{{url}}\">neem een abonnement op Boer&Bunder</a>.",
    "SubscribeToSeeSharedData": "<a href=\"{{href}}\">Abonneer</a> je op Boer&Bunder om de gedeelde data te kunnen zien. Als je al een abonnement hebt, login en zet de Veldselectie-tool onder de Instellingen aan.",
    "Subscription": "Abonnement",
    "Subscriptions": "Abonnementen",
    "Sugar beet": "Suikerbiet",
    "Summer rye": "Zomerrogge",
    "Sunflower": "Zonnebloem",
    "Sustainable lease conditions": "Duurzame pachtmogelijkheden",
    "Switch to": "Wissel naar",
    "Tel +31 88 3226600": "Tel: +31 88 3226600",
    "Tell me more about the subscriptions": "Informatie over abonnementen",
    "Terms": "Voorwaarden",
    "TextAllImagesPro": "Neem een abonnement om elk beschikbare beeld op de kaart te bekijken. Zonder Boer&Bunder Pro wordt alleen één (redelijk recent) demo-beeld op de kaart getoond. Met een abonnement zijn alle 'puntjes' aanklikbaar en is elk beeld per perceel op de kaart te zien.",
    "TextBetterUse": "om nog beter van Boerenbunder gebruik te maken.Met een abonnement kun je percelen opslaan voor later, lagen op hoog niveau bekijken en uitgebreide zoekfunctionaliteit gebruiken. Ook kun je percelen printen en exporteren naar shapefile en andere formaten.",
    "TextCookies": "Daarnaast maakt Boer&Bunder gebruik van cookies. Wij gebruiken cookies voor het bijhouden van statistieken (geanonimiseerd op basis van IP-adres) en om de gebruikerservaring te optimaliseren. De cookies worden niet gedeeld met derden en na {{cookieDays}} dagen worden de cookies automatisch weer verwijderd. Lees hier meer over in onze <a href=\"{{termsAndConditionsUrl}}\">algemene voorwaarden</a> en <a href=\"{{privacyStatementUrl}}\">privacyverklaring</a>.",
    "TextDownloadSearchFilter": "Download de percelen uit je zoekfilter. De percelen worden gebundeld met attributen in een Excel bestand en een Shapefile.",
    "TextFieldsInView": "Zo heb je altijd de interessante percelen met één klik in zicht",
    "TextLetUsKnow": "Laat ons horen wat je van Boer&Bunder vindt. De doorontwikkeling van Boer&Bunder wordt gestuurd door de vele vragen van gebruikers. Heb je een vraag of opmerking? We horen het graag.",
    "TextLikeFields": "Om een bunder te volgen kun je een perceel op de kaart selecteren en op het hartje klikken",
    "TextNationalLayer": "Met een Boer&Bunder abonnement kun je {{layer}} op landelijk niveau zien",
    "TextSettingsInfo": "Configureer je Boer&Bunder ervaring door geavanceerde opties aan- en uit te zetten. Deze features zijn alleen beschikbaar voor PRO of hoger. Probeer ze een-voor-een en laat ons weten wat je ervan vindt via",
    "TextTerms": "Boer&Bunder visualiseert open data van percelen. Deze data is afkomstig van openbare bronnen die diverse overheden aanbieden. Dacom kan geen garanties geven over de kwaliteit of juistheid van de data. Wij visualiseren de informatie naar ons beste kunnen. Voor de juistheid en actualiteit verwijzen we je naar de bronhouder. In de applicatie benoemen we bronhouders waar mogelijk onder de ",
    "TextViewedTooManyPlots": "Je hebt deze week al {{maxFields}} percelen gratis bekeken! We willen graag ons onafhankelijk platform Boer&Bunder reclamevrij en up-to-date houden en goede service te verlenen. Help ons hierbij als professionele gebruiker en neem voor €16 per maand een Pro abonnement.",
    "TextViewedTooManyPlotsNoCookies": "Om ons onafhankelijk platform Boer&Bunder reclamevrij en up-to-date te houden, goede service te verlenen en te voorzien van meer relevante datasets zijn wij veel tijd kwijt. Hierdoor hebben wij gekozen om voor €16 per maand professionele gebruikers te voorzien van een Pro abonnement. ",
    "TextWithAProSubscription": "<p>Met een Pro abonnement</p><ul><li>Vind en navigeer je snel naar percelen</li><li>Weet je altijd de juiste beteelbare oppervlakte van percelen</li><li>Bekijk je eenvoudig gewasrotaties voor het maken van bouwplannen</li><li>Kun je hoogtekaarten en satellietbeelden gebruiken voor precisielandbouw</li><li>Vind je met de kadastrale informatie geschikte grond voor huurland of koop</li></ul>",
    "Thanks for you message": "Bedankt voor je bericht",
    "The farm": "De boerderij",
    "The list is empty.": "De lijst is leeg.",
    "The public state of your farm has been changed": "De openbare status van je boerderij is gewijzigd",
    "The public state of your list has been changed": "De openbare status van je lijst is gewijzigd",
    "This field could not be found!": "Dit bunder kon niet gevonden worden!",
    "This field is located within the boundaries of nature collective": "Dit perceel valt binnen de grenzen van Agrarisch Natuurcollectief",
    "This field is part of habitats": "Dit perceel is onderdeel van volgende leefgebieden",
    "This field is part of the Nature Network of this country": "Dit perceel is onderdeel van het Natuur Netwerk Nederland",
    "This field is used as <i>{{useTitle}}</i>": "Dit perceel valt onder <i>{{useTitle}}</i>",
    "This is a pro feature": "Dit is een pro feature",
    "This plot is owned by state institute <i>{{name}}</i>": "Dit perceel is eigendom van overheidsdienst <i>{{name}}</i>",
    "This session has expired or has been terminated remotely with a different login": "Deze sessie is verlopen of beëindigd omdat dit account op teveel apparaten is ingelogd",
    "Title": "Titel",
    "Today": "Vandaag",
    "Tomato": "Tomaat",
    "Total": "Totaal",
    "Tree": "Boom",
    "Trenches": "Sloten",
    "Tulip": "Tulp",
    "Unknown": "Onbekend",
    "Unselect plots": "Selectie ongedaan maken",
    "Upload": "Upload",
    "Uploading": "Uploading",
    "Uploading this file will overwrite your current lease list": "Door dit bestand te uploaden wordt de huidige pachtlijst overschreven",
    "Use title": "Gebruikstitel",
    "Vegetables": "Groente",
    "Video": "Video",
    "View all datasets": "Bekijk alle datasets",
    "View cadastral data": "Bekijk kadastrale data",
    "View crop rotations for creating a healthy cultivation plan": "Bekijk gewasrotaties voor het maken van een gezond bouwplan",
    "View many historic crop rotation years": "Kijk vele jaren terug in gewashistorie",
    "View {{subject}} on CropFields": "Bekijk {{subject}} op Boer&Bunder",
    "WDVI images": "WDVI-beelden",
    "Water board": "Waterschap",
    "Water trap": "Watertrap",
    "Website": "Website",
    "With a crop recording subscription, you can work with multiple users on the same company & subscription": "Met een teeltregistratie abonnement kun je met meerdere mensen op het zelfde bedrijf & abonnement werken",
    "With a free account you can use many extra functionalities:": "Met een gratis account kun je gebruik maken van veel extra functionaliteiten:",
    "With the free version of CropFields, you can view the basic data of a plot such as crop group, location and agricultural area.": "Met de gratis variant van Boer&Bunder kun je wel de basisgegevens van een perceel inzien, zoals gewasgroep, locatie en landbouwgebied.",
    "Without a subscription, you can view data of {{maxFields}} crop fields per week for free.": "Zonder abonnement kun je data van {{maxFields}} percelen per week gratis bekijken.",
    "Would you like to view additional crop fields?": "Wil je meer percelen zien?",
    "XY coordinates": "XY coördinaten",
    "Yes, make my farm profile public": "Ja, maak mijn boerderijprofiel publiek",
    "Yes, make one of my lists public": "Ja, maak een van mijn lijsten publiek",
    "You can be logged in on up to two devices": "Je kunt met maximaal twee apparaten op hetzelfde account zijn ingelogd",
    "Your contact information is public to other users.": "Je contactgegevens zijn publiek voor andere gebruikers.",
    "Your one-month trial subscription has expired": "Je proefabonnement van een maand is verlopen",
    "Your question or remark": "Je vraag of opmerking",
    "Zip code": "Postcode",
    "Zipcode/city": "Postcode/plaats",
    "all images": "alle beelden",
    "average": "gemiddeld",
    "avg": "gem",
    "by": "van",
    "crop rotation": "voorvruchten van vorige jaren",
    "difference": "verschil",
    "different attributes": "overige kenmerken",
    "different soil types": "verschillende grondsoortenindelingen",
    "error": "fout",
    "example": "voorbeeld",
    "fields": "percelen",
    "filterTextAccount": "Maak een account aan of log in om alle filteropties te gebruiken",
    "get a free trial subscription to CropFields": "neem een proefabonnement op Boer&Bunder",
    "heighest": "hoogst",
    "l_cadastre_p1": "Kadaster p1",
    "l_cadastre_p2": "Kadaster p2",
    "l_info_bufferzones": "Een bufferstrook is een strook grond waarop je geen mest, chemische gewasbeschermingsmiddelen of biociden mag gebruiken. Hiermee wordt de waterkwaliteit beschermd en biodiversiteit gestimuleerd. Een bufferstrook ligt langs een waterloop die langs landbouwgrond ligt.",
    "l_info_ews": "De infectieradar stelt je in staat dreigende risico's voor je gewassen als gevolg van ziekten te herkennen.",
    "l_info_facts": "Boeren moeten jaarlijks een opgave van al hun percelen indienen bij de overheid. Met deze open data kan je de gewasrotatie bekijken.",
    "l_info_growth": "Actuele vegetatiekaart verkregen uit satellietbeelden. Volg per perceel bijvoorbeeld wanneer wordt gezaaid en geoogst.",
    "l_info_height": "Digitale hoogtekaart van Nederland. Standaard tonen we AHN1. Abonnees wisselen met verschillende jaartallen tussen AHN1, AHN2, AHN3 en AHN4.<br /> Bron: <a href=\"https://www.ahn.nl/over-ahn\">AHN</a> / <a href=\"https://www.pdok.nl/rasterdata\">PDOK</a>",
    "l_info_lease": "Verpacht je zelf dit perceel en wil je je duurzaam pachtbeleid zichtbaar maken? Kijk onder <a href=\"/page/lease\">pacht</a> hoe dit werkt.",
    "l_info_nature": "Natura 2000 is een Europees netwerk van beschermde natuurgebieden. In Natura 2000-gebieden worden bepaalde diersoorten en hun natuurlijke leefomgeving beschermd om de biodiversiteit te behouden.<br />Zie het <a href=\"https://ec.europa.eu/environment/nature/natura2000/index_en.htm\">Europese overzicht</a>.",
    "l_info_property": "Perceelsgrenzen van het lokale Kadaster",
    "l_info_soil": "Globaal overzicht van de bodemclassificering. De indeling verschilt per land.",
    "l_info_use_title": "Hier tonen we de gebruikstitel zoals vrijgegeven door het RVO en verzameld tijdens de gecombineerde opgave van 2022. <b>Deze data is niet gecontroleerd door het RVO</b>, het is indicatief en hier kunnen geen rechten aan worden ontleend. Het kan voorkomen dat er een mismatch is tussen BRP-percelen en de kadastrale secties die een specifieke gebruikstitel hebben.",
    "l_info_water": "Informatie over lokale waterwegen",
    "l_info_weather": "Neerslagtekort",
    "legend": "legenda",
    "length": "lengte",
    "lowest": "laagst",
    "max": "max",
    "meter": "meter",
    "min": "min",
    "more years": "meer jaren",
    "nr": "nr",
    "or": "of",
    "province and municipality borders": "provincie- en gemeentegrenzen",
    "restrictedAccount": "Maak een gratis account aan of log in",
    "settings_WDVI_images": "Een nadeel van NDVI is dat satellietbeelden later in het seizoen het verschil niet meer kunnen zien tussen een vol en een zeer vol gewas. WDVI-beelden hebben daar minder last van. Deze groei-index kijkt ook naar de reflectie van de kale bodem. Het contrast wordt daardoor beter.",
    "settings_crop_predictor_feature_warning": "Deze feature staat even uit. Laat ons even horen als je dit een relevante optie vindt, dan komt het wat hoger op de prioriteitenlijst.",
    "settings_crop_predictor_hanze": "In samenwerking met de Hanzehogeschool Groningen heeft Dacom een model ontwikkeld ontwikkeld waarmee toekomstige gewassen kunnen worden voorspeld, de Gewasvoorspeller.",
    "settings_crop_predictor_info_1": "Zet de toggle aan, zodat je bij elk gewasperceel in Boer&Bunder kunt zien welke gewassen wij in 2021 bij ieder perceel voorspellen op basis van eerdere gewasrotaties én actuele satellietbeelden.",
    "settings_crop_predictor_info_2": "Met behulp van deep learning AI en algoritmes legt het model onderliggende patronen in de dataset bloot. De input van het model is in eerste instantie gebaseerd op de gewasrotatie van alle Nederlandse percelen zoals deze geregistreerd staan in de Basis Registratie Percelen voor de periode van 2005 tot 2020. De voorspelling voor 2021 zal gedurende het jaar steeds beter worden, omdat nieuwe satellietbeelden beschikbaar komen. Het model kijkt dan bijvoorbeeld naar de NDVI-groeicurve van een perceel en vergelijkt deze met de voorgaande jaren.",
    "settings_crop_predictor_info_3": "Na het aanzetten van de toggle, geven we op Boer&Bunder bij elk perceel drie gewassen weer die volgens het model het meest waarschijnlijk zijn. Een hoog percentage voor een gewas betekent dat het model eerder een zodanig patroon in de Nederlandse percelen heeft waargenomen en dat het model er vanuit gaat dat dit gewas in 2021 het meest waarschijnlijk zal zijn.",
    "settings_ditches": "In Boer&Bunder kun je nu de sloten zien die direct rondom een perceel liggen. Ook kun je de sloten als achtergrondlaag gebruiken. Bij elk perceel kun je de lengte van de sloten vinden. Dit is handig voor boeren en loonwerkers voor bijvoorbeeld de facturatie van slootonderhoud. Ook is te zien waar de insteek van de sloot begint. Daar begint ook de spuit- en teeltvrije zone van een perceel.",
    "settings_drainage_map": "De afwateringskaart is gemaakt op basis van de meest actuele hoogtekaart. Je kan zien waar het water naartoe stroomt. Je kunt deze kaart bijvoorbeeld gebruiken voor het analyseren van erosie op je perceel of voor het graven van sleuven als je wateroverlast hebt.",
    "settings_ews_feature": "Met de Infectieradar van Dacom krijg je tijdig informatie over het infectierisico van een 20-tal schimmelziektes. De modellen van Dacom dekken hiermee de belangrijkste cultuurgewassen van Nederland. In eerste instantie beginnen we klein, maar in de loop van het seizoen zullen er steeds meer functionaliteiten aan worden toegevoegd. Hou de voortgang in de gaten door regelmatig te klikken op Radar in de linker menubalk van Boer&Bunder.",
    "settings_field_selection_tool": "Als boer wordt je vaak gevraagd om je percelen te delen. Met de nieuwe veldselectie-tool kun je nu ook meerdere percelen tegelijk delen. Klik rechtsboven op het percelen icoontje en vervolgens kun je meerdere percelen aanklikken om zo een handig lijstje samen te stellen. Verstuur daarna via WhatsApp, Twitter, Facebook of via je mail. De ontvanger ziet in Boer&Bunder precies hetzelfde lijstje als jij verstuurd hebt. Als je wilt kun je ook een Shape-bestand versturen met daarbij een handig Excel-bestand.",
    "settings_growth_course": "Met onze nieuwe tijdlijn kun je in één oogopslag de ruimtelijke variatie van een perceel door de tijd heen zien. Zo kun je nog sneller afwijkende plekken in een perceel ontdekken of het juiste satellietbeeld selecteren voor een taakkaart.",
    "settings_lease": "Deel zelf informatie over de verpachting van een perceel. Geef aan wat het gehanteerde (duurzame) pacht-beleid per perceel is.",
    "settings_precip_deficit": "Het neerslagtekort visualiseert hoe droog het is op verschillende plekken in het land.",
    "settings_shadow_map": "De schaduwkaart geeft op ieder gewasperceel aan hoeveel schaduw er tijdens het groeiseizoen op het perceel valt. Je kunt deze informatie bijvoorbeeld gebruiken om de rijafstand in de schaduwrijke delen aan te passen of om achteraf te analyseren waarom de opbrengst op bepaalde stukken van het perceel achterblijven bij de rest.",
    "settings_soil_potency_map": "Met de bodempotentiekaart laten we zien welke delen van een perceel structureel beter of minder presteren. Dit doen we door satellietbeelden van de laatste 5 jaar over elkaar te leggen. Zo ontdekken we patronen en halen we seizoensinvloeden er uit. Je kunt deze kaart bijvoorbeeld gebruiken voor het maken van een bemestingsplan of om te zien of er maatregelen nodig zijn die de bodemstructuur kunnen verbeteren.",
    "show trenches, ditch sides": "toon oever, slootkanten",
    "subscribe here to CropFields": "neem hier een Boer&Bunder abonnement",
    "the CropFields subscription": "het boerenbunder abonnement",
    "the nature collective": "het natuurcollectief",
    "this blog": "deze Blog",
    "this farm": "deze boerderij",
    "this field": "dit perceel",
    "this field selection": "deze veldselectie",
    "type": "type",
    "waterways": "waterlopen",
    "{{days}} days remaining": "Nog {{days}} dagen",
    "{{fields}} Fields": "{{fields}} Percelen",
    "{{hitsCount}} results": "{{hitsCount}} resultaten",
    "{{percentage}}% chance on {{subject}}": "{{percentage}}% kans op {{subject}}"
}},
};
export default translations;
