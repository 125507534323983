import React, { useCallback, useState } from "react";
import { config } from "../Config";
import { AccountModal } from "./AccountModal";
import { Modal } from "react-bootstrap";
import i18n from "i18next";
import bertHank from "../../img/logo/bert-hank_abo.png";
import { TS, useSSelector } from "../util";
import { freeTrialClick } from "../components/Pro";

export const SubscribeModalClicks = ({ bbCookieAccepted, show }) => {
  const isloggedIn = useSSelector((state) => state.activeUser.state === "loggedIn");
  const [showModal, setModal] = useState(true);
  const onClose = useCallback(() => setModal(false), []);
  const maxFields = config.MAX_FREE_CLICKS;
  if (!show) return null;
  if (config.bb_site.beta) {
    return AccountModal(showModal, onClose);
  }

  return (
    <Modal
      show={showModal}
      onHide={onClose}
      aria-labelledby="contained-modal-title"
      className="mod_bb_subscribe"
      animation={false}
      backdrop="static"
      backdropClassName="bb-modal-backdrop"
    >
      <Modal.Header closeButton>
        <Modal.Title>{i18n.t("Subscribe to use this functionality")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="float-right">
          <img alt="Bert&Hank" className="d-none d-sm-block" width="200" src={bertHank} />
          <img alt="Bert&Hank" className="d-inline-block d-sm-none" width="150" src={bertHank} />
        </div>
        <h4>{i18n.t("Would you like to view additional crop fields?")}</h4>
        <p>
          {bbCookieAccepted
            ? i18n.t("TextViewedTooManyPlots", { maxFields })
            : i18n.t("TextViewedTooManyPlotsNoCookies")}
        </p>
        <div>
          <TS t="TextWithAProSubscription" />
          <p>
            <TS
              t={
                'Do you want more information about the Pro subscription? Then click <a href="{{url}}">here</a>.'
              }
              o={{
                url: `/page/subscription`,
              }}
            />{" "}
            {bbCookieAccepted ? (
              <TS
                t="Without a subscription, you can view data of {{maxFields}} crop fields per week for free."
                o={{
                  maxFields,
                }}
              />
            ) : (
              <TS t="With the free version of CropFields, you can view the basic data of a plot such as crop group, location and agricultural area." />
            )}
          </p>
        </div>
        <p style={{ fontStyle: "italic" }}>
          <TS
            t={
              'Do you want to use CropFields with multiple users in your company? Please contact us via <a href="mailto:info@dacom.nl">info@dacom.nl</a>.'
            }
          />
        </p>
      </Modal.Body>
      <Modal.Footer>
        {config.bb_site.free_trial && !isloggedIn ? (
          <a
            className="btn btn-success float-left"
            href="/authorize?create=1"
            onClick={freeTrialClick}
          >
            {i18n.t("Free trial")}
          </a>
        ) : (
          <a className="btn btn-success float-left" href={`${config.bb_orderPage}`}>
            {i18n.t("Order a subscription")}
          </a>
        )}
        <a className="btn btn-info" href="/authorize">
          {i18n.t("Login")}
        </a>
      </Modal.Footer>
    </Modal>
  );
};
